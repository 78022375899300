import React from "react";
import {Button, Grid, Icon, Input, Popup} from "semantic-ui-react";
import {Mixpanel} from "../utils/Mixpanel";
import Vault from "../Vault";
import './NewIdentityView.css';
import PulseLoader from "react-spinners/PulseLoader";

var validator = require('validator');

export class NewIdentityView extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            invalidDomain: false,
            createIdInProgress: false,
            showMessage: false,
            showError: false,
            error: 'Error occurred, please try again.',
            totalLimitReached: props.totalLimitReached,
            perDomainLimitReached: false
        }

        this.onCreateIdentityClick = this.onCreateIdentityClick.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        if (props.totalLimitReached !== state.totalLimitReached) {
            return {
                'totalLimitReached' : props.totalLimitReached
            };
        }
        return null;
    }

    onValueChange = (e, { name, value }) => {
        this.setState({ [name]: value });
    }

    onCreateIdentityClick() {
        Mixpanel.track('New Identity clicked');

        if (!this.state.domain || !validator.isURL(this.state.domain)) {
            this.setState({
                invalidDomain: true
            });

            Mixpanel.track('New Identity | validation failed');
            return;
        }

        const rawDomain = this.getRawDomain(this.state.domain);

        if (Vault.countIdentitiesFor(rawDomain) == 2) {
            this.setState({
                perDomainLimitReached: true
            });
            return;
        }

        this.setState({
            invalidDomain: false,
            createIdInProgress: true,
            showMessage: false,
            showError: false,
            perDomainLimitReached: false,
        });

        Vault.generateNewIdentityFor(rawDomain, this.state.note)
            .then(id => {
                Mixpanel.track('New Identity success');
                this.setState({
                    note: '',
                    domain: '',
                    createIdInProgress: false,
                    showMessage: true,
                    showError: false,
                });

                if (this.props.onIdentityCreated) {
                    this.props.onIdentityCreated(id);
                }

                setTimeout(() => { this.setState({ showMessage: false })}, 2500)

            })
            .catch(err => {
                Mixpanel.track('New Identity failed', { err });
                this.setState({
                    createIdInProgress: false,
                    showMessage: false,
                    showError: true
                });
                setTimeout(() => { this.setState({ showError: false }) }, 2500)

            });

    }

    getRawDomain(domain) {
        return domain.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "").split('/')[0];
    }

    handlePopupOpen = () => {
        this.setState({ isPopupOpen: true })

        this.timeout = setTimeout(() => {
            this.setState({ isPopupOpen: false })
        }, 1500)
    }

    handlePopupClose = () => {
        this.setState({ isPopupOpen: false })
        clearTimeout(this.timeout)
    }

    render() {

        return (

            <Grid stackable className='new-identity'>

                <Grid.Row>
                    <Grid.Column className='new-identity-subheader-wrapper'>
                        <div className='new-identity-subheader'>
                            {/*<p className='p-first'>Have a unique Identity for each service or application you use</p>*/}
                            <p className='p-first'>Get unique Identity for each service you use</p>
                            <p>Big Tech and criminals will not learn all about you across different platforms</p>
                        </div>
                    </Grid.Column>
                </Grid.Row>

                {this.state.totalLimitReached && (
                    <div className='limit-info-wrapper'>
                        <div className='limit-info-text'>You reached identities limit.</div>
                        <Popup
                            trigger={
                                <Button className='btn-upgrade-account'>
                                    Upgrade account
                                </Button>
                            }
                            content={`This option will be available soon`}
                            on='click'
                            open={this.state.isPopupOpen}
                            onClose={this.handlePopupClose}
                            onOpen={this.handlePopupOpen}
                            position='bottom center'
                        />

                    </div>
                )}

                {!this.state.totalLimitReached && !this.state.showError && !this.state.showMessage && (

                    <Grid.Row columns={3} style={{ minHeight: '64px', paddingTop: '0px !important'}}>

                        <Grid.Column tablet={8} computer={6} className='new-identity-fist-column'>

                            <Input
                                icon='world' iconPosition='left'
                                className='new-identity-input'
                                placeholder='website URL'
                                name='domain'
                                error={this.state.invalidDomain}
                                disabled={this.state.createIdInProgress}
                                value={this.state.domain} onChange={this.onValueChange}>
                                <Icon name='world'/>
                                <input style={this.state.invalidDomain ? { backgroundColor: 'white', border: '1px #FF4136 solid'} : {border: 'none'}} />
                            </Input>

                        </Grid.Column>

                        <Grid.Column tablet={8} computer={6} style={{paddingLeft: '0'}}>
                            <Input className='new-identity-input' iconPosition='left'
                                   name='note'
                                   disabled={this.state.createIdInProgress}
                                   value={this.state.note} placeholder='short note e.g shopping'
                                   onChange={this.onValueChange}
                            >
                                <Icon name='sticky note' />
                                <input style={{border: 'none'}}/>
                            </Input>
                        </Grid.Column>

                        <Grid.Column tablet={16} computer={4} className='wrapper-btn-create-id' style={{ marginBottom: '32px !important'}}>

                            <Button className='btn-create-id'
                                    disabled={this.state.createIdInProgress}
                                    icon
                                // size='large'
                                    style={{backgroundColor: '#010101', color: '#ffffff', padding: '0 16px !important' }}
                                    onClick={this.onCreateIdentityClick}>

                                {!this.state.createIdInProgress && (

                                    <div>
                                        {/*<Icon style={{ color: '#ffffff', fontSize: '16px'}} name='user'/>*/}
                                        <span style={{ }}>Create Identity</span>
                                    </div>

                                )}

                                {/*<Loader size='tiny' style={{position: 'relative', top: '8px', padding: '0', }} active inverted/>*/}

                                {this.state.createIdInProgress && (

                                    <PulseLoader
                                        size={10}
                                        color={"#ffffff"}
                                        loading={true}
                                    />

                                )}



                            </Button>

                        </Grid.Column>

                    </Grid.Row>
                )}

                { this.state.showMessage && (

                    <Grid.Row columns={1} style={{ minHeight: '84px'}}>
                        <Grid.Column computer={16} style={{paddingLeft: '0', }}>
                            <div style={{width: '100%', height: '100%', backgroundColor: '#4FBBBC', fontSize: '19px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white', borderRadius: '4px', textAlign: 'center'}}>
                                <Icon size='big' name='checkmark' style={{marginRight: '16px'}}/>
                                Identity is ready</div>
                        </Grid.Column>
                    </Grid.Row>

                )}

                { this.state.showError && (

                    <Grid.Row columns={1} style={{ minHeight: '84px'}}>
                        <Grid.Column computer={14} style={{paddingLeft: '0', }}>
                            <div style={{width: '100%', height: '100%', backgroundColor: '#f76262', fontSize: '19px', display: 'flex', justifyContent: 'center', fontWeight: '300', alignItems: 'center', color: 'white', borderRadius: '4px', textAlign: 'center'}}>
                                <Icon size='large' name='warning circle' style={{marginRight: '16px'}}/>
                                {this.state.error}</div>
                        </Grid.Column>
                    </Grid.Row>

                )}

                {this.state.perDomainLimitReached && (
                    <Grid.Row columns={1} style={{ minHeight: '64px', paddingTop: '0px !important', marginTop: '-8px !important', color: '#F76262'}}>

                        <Grid.Column>
                            <Icon name='warning sign'/>
                            You can have
                            <strong style={{fontWeight: '500'}}> at most two IDs </strong>
                            for one domain.
                        </Grid.Column>

                    </Grid.Row>
                )}

            </Grid>

        );
    }

}
import React from "react";
import {Button, Dimmer, Grid, Image, Label, Input, Loader, Segment} from "semantic-ui-react";
import 'semantic-ui-css/semantic.css';
import Auth from "../Auth";
import Vault from "../Vault";
import {Mixpanel} from "../utils/Mixpanel";
import './SignUp.css'
import Progress from "semantic-ui-react/dist/commonjs/modules/Progress";
import {TwilioDevice} from "../utils/TwilioDevice";
var zxcvbn = require('zxcvbn');
var passwordValidator = require('password-validator');

export class SignUp extends React.Component {

    passwordStrengthMessage = {
        0: {msg: "too guessable", color: "red"},
        1: {msg: "very guessable", color: "red"},
        2: {msg: "somewhat guessable", color: "yellow"},
        3: {msg: "safely unguessable", color: "olive"},
        4: {msg: "very unguessable", color: "green"}
    };

    passwordSchema;
    maxPasswordStrength = 4;
    acceptablePasswordStrength = 3;

    constructor(props) {
        super(props);

        this.state = {
            signUpFailed: false,
            inProgress: false,
            loginFailed : false,
            identicalPassword : true,
            invalidCode: false,
            codeVerificationFailed: false,
            width: window.innerWidth,
            passwordStrength: null,
        };

        this.initializePasswordSchema();

        this.handleCreateClick = this.handleCreateClick.bind(this);
        this.validatePassword = this.validatePassword.bind(this);
        this.validatePasswordRepeat = this.validatePasswordRepeat.bind(this);
    }

    initializePasswordSchema() {
        this.passwordSchema =
            new passwordValidator()
                .is().min(8)
                .is().max(100)
                .has().uppercase()
                .has().lowercase()
                .has().digits()
                .has().symbols()
                .has().not().spaces();
    }

    componentDidMount() {
        Mixpanel.track('Sign Up Visit');
    }

    componentWillMount() {
        window.addEventListener('resize', this.handleWindowSizeChange);
    }

// make sure to remove the listener
// when the component is not mounted anymore
    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ width: window.innerWidth });
    };


    validateUsername(username) {
        if (!username || username.length < 6 || username.length > 48) {
            return false;
        }
        const re = /^[0-9a-zA-Z]+$/g;
        return re.test(username);
    }

    handleCreateClick(e) {
        e.preventDefault();

        Mixpanel.track('Sign Up | Create clicked');

        // if (!validator.validate(this.state.noidUsername)) {
        if (!this.validateUsername(this.state.noidUsername)) {
            this.setState({
                invalidEmail: true,
                inProgress : false
            });

            Mixpanel.track('Sign Up | Validation failed');
            return;
        }

        if (!this.state.noidPassword) {
            this.setState({
                invalidEmail: false,
                passwordStrength: 0
            });
            return;
        }

        if (this.state.passwordStrength < this.acceptablePasswordStrength) {
            this.setState({
                invalidEmail: false,
            });
            return;
        }

        if (this.state.noidPassword !== this.state.noidPasswordRepeat) {
            this.setState({
                invalidEmail: false,
                identicalPassword: false
            });
            return;
        }

        if (!this.state.registrationCode || this.state.registrationCode.trim() == "") {
            this.setState({
                invalidEmail: false,
                identicalPassword: true,
                invalidCode: true
            });
            return;
        }

        this.setState({
            invalidEmail : false,
            identicalPassword: true,
            invalidCode: false,
            codeVerificationFailed: false,
            inProgress: true
        });

        this.signUp();
    }

    signUp() {

        Auth.signUp(this.state.noidUsername, this.state.noidPassword, this.state.registrationCode, (signUpResult, code) => {

            if (!signUpResult.status) {
                console.log("singUp FAILED!!!");
                this.setState({
                    signUpFailed: true,
                    inProgress: false,
                    // codeVerificationFailed: code && code == 'INVALID_REGISTRATION_CODE',
                    // invalidCode: code && code == 'INVALID_REGISTRATION_CODE'
                });

                Mixpanel.track('Sign Up | Failed');
                return;
            }
            Mixpanel.track('Sign Up | Success');

            Auth.login(this.state.noidUsername, this.state.noidPassword, (loginResult) => {

                if (!loginResult.status) {
                    this.setState({
                        loginFailed: true,
                        inProgress: false
                    });

                    Mixpanel.track('Sign Up | Login failed');
                    return;
                }

                Mixpanel.track('Sign Up | Login success');

                if (signUpResult && signUpResult.ownerIdentityId && loginResult.vaultId) {

                    Vault.setupFirstTime(this.state.noidPassword, signUpResult.vaultId, signUpResult.ownerIdentityId)
                        .then(() => {
                            Vault.initIdentities(loginResult.vaultId, this.state.noidPassword, () => {
                                this.props.history.push('/main');
                            });
                            TwilioDevice.startTwilioDevices();
                        })
                        .catch(err => {
                            console.error("Problem setting Vault the first time: " + err);
                        });

                    return;
                }

                Vault.initIdentities(loginResult.vaultId, this.state.noidPassword, () => { this.props.history.push('/main'); });
                TwilioDevice.startTwilioDevices();
            });


        });
    }

    onValueChange = (e, { name, value }) => {
        this.setState({ [name]: value });

        switch (name) {
            case 'noidPassword':
                this.validatePassword(e);
                return;
            case 'noidPasswordRepeat':
                this.validatePasswordRepeat(e);
                return;
        }
    };

    validatePassword(e) {
        const password = e.target.value;
        let passwordStrength;
        if (!this.passwordSchema.validate(password)) {
            passwordStrength = 1;
        } else {
            passwordStrength = zxcvbn(password).score;
        }
        this.setState({passwordStrength});
    }

    validatePasswordRepeat(e) {
        const passwordRepeat = e.target.value;
        this.setState({identicalPassword: this.state.noidPassword === passwordRepeat})
    }

    render() {
        const isMobile = this.state.width <= 480;
        const passwordStrength = (this.state.passwordStrength / this.maxPasswordStrength) * 100;
        return (

            <Grid style={{width: '100vw', height: '100vh', marginTop: '0px', marginBottom: '0rem'}} className='signup-grid-wrapper' stackable>
                <Grid.Row columns={2} style={{width: '100%', height: '100%', paddingTop: '0px !important'}}>
                    <Grid.Column width={10} className='signup-desc-wrapper'>

                        <Image size='tiny' className='signup-logo' src='/images/logo-small.png'/>

                        <div className='signup-head'>

                            <div className='signup-subheader'>
                                Mask your Identity.
                            </div>

                            <div className='signup-subheader2'>
                                Get peace of mind.
                            </div>

                        </div>
                    </Grid.Column>
                    <Grid.Column width={6} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>

                        {/*<Image size='tiny' src='/images/logo-small.png' style={{ position: 'absolute', top: '48px', right: '128px', margin: '0 auto' }}/>*/}

                        <Segment.Group style={{textAlign: 'center', maxWidth: '500px', border: '0', boxShadow: 'none' }}>
                            <Segment>

                                <Input
                                    error={this.state.invalidEmail}
                                    disabled={this.state.inProgress}
                                    style={{width: '90%', marginBottom: '4px', fontSize: '18px'}} icon='user' iconPosition='left' name='noidUsername' placeholder='Username' value={this.state.noidUsername} onChange={this.onValueChange} />
                                <div style={{width: '100%', marginBottom: '16px', color: 'grey', fontSize: '12px'}}>Between 6 and 42 characters. E.g adamo432</div>

                                <Input
                                    disabled={this.state.inProgress}
                                    error={this.state.passwordStrength !== null && this.state.passwordStrength < this.acceptablePasswordStrength}
                                    style={{width: '90%', marginBottom: '16px', fontSize: '18px'}} type='password' icon='lock' iconPosition='left' name='noidPassword' value={this.state.noidPassword} placeholder='Password' onChange={this.onValueChange} />

                                <Input
                                    disabled={this.state.inProgress}
                                    error={!this.state.identicalPassword}
                                    style={{width: '90%', marginBottom: '4px', fontSize: '18px'}} type='password' icon='lock' iconPosition='left' name='noidPasswordRepeat' value={this.state.noidPasswordRepeat} placeholder='Repeat Password' onChange={this.onValueChange} />

                                {this.state.noidPassword &&
                                    <div>
                                        <Progress style={{width: '90%', display: 'inline-block', 'margin-bottom' : 0}} percent={passwordStrength} color={this.passwordStrengthMessage[this.state.passwordStrength].color} size='tiny' />
                                        <div style={{width: '100%', marginBottom: '16px', color: 'grey', fontSize: '12px'}}>
                                            {this.state.passwordStrength <= 1 ?
                                                'Min. 8 characters, at least one lowercase, uppercase, digit and symbol' :
                                                'Password strength - ' + this.passwordStrengthMessage[this.state.passwordStrength].msg
                                            }
                                        </div>
                                    </div>
                                }
                                <div style={{width: '100%', marginBottom: '16px', color: '#d05c5c', fontSize: '16px', fontWeight: '700'}}>If you forget the password, it's impossible to recover it</div>

                                <Input
                                    disabled={this.state.inProgress}
                                    error={this.state.invalidCode}
                                    style={{width: '90%', fontSize: '18px'}} icon='question circle' iconPosition='left' name='registrationCode' value={this.state.registrationCode} placeholder='Registration Code' onChange={this.onValueChange} />

                                {this.state.signUpFailed && (
                                    <div style={{color: 'red', marginTop: '16px', fontSize: '18px'}}>Problem's occurred. Please try again</div>
                                )}

                                {/*{this.state.codeVerificationFailed && (*/}
                                    {/*<div style={{color: 'red', marginTop: '16px'}}>Invalid registration code. Check code once again or visit or page <a href="url">https://noid.today/</a> and ask for access.</div>*/}
                                {/*)}*/}

                            </Segment>

                            <Segment>

                                {!this.state.inProgress && (
                                    <Button color='black' onClick={this.handleCreateClick} style={{ color: 'white', width: '90%', margin: '0 auto', fontSize: '18px'}} >
                                        Create Account</Button>
                                )}

                                {this.state.inProgress && (
                                    <Segment style={{height: '38px', width: '90%', margin: '0 auto', fontSize: '18px'}}>
                                        <Dimmer active>
                                            <Loader/>
                                        </Dimmer>
                                    </Segment>
                                )}

                            </Segment>

                        </Segment.Group>

                    </Grid.Column>
                </Grid.Row>
            </Grid>

        )}

}
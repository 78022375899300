/*global forge*/

export default class CryptoUtils {

    static encryptAes256GCM(data, key0) {

        let key = Buffer.from(key0).toString('binary');
        const iv = forge.random.getBytesSync(16);

        // encrypt some bytes using GCM mode
        let cipher = forge.cipher.createCipher('AES-GCM', key);
        cipher.start({
            iv: iv, // should be a 12-byte binary-encoded string or byte buffer
            tagLength: 128
        });
        cipher.update(forge.util.createBuffer(data, 'utf8'));
        cipher.finish();

        const encrypted = cipher.output;
        const tag = cipher.mode.tag;

        return {
            iv: forge.util.bytesToHex(iv),
            encrypted: encrypted.toHex(),
            tag: tag.toHex()
        }
    }

    static decryptAes256GCM(encryptedHex, key0, ivHex, tagHex) {

        const key = Buffer.from(key0).toString('binary');
        const iv = forge.util.hexToBytes(ivHex);
        const tag = this.hexToBuffer(tagHex);
        const encrypted = this.hexToBuffer(encryptedHex);

        // decrypt some bytes using GCM mode
        let decipher = forge.cipher.createDecipher('AES-GCM', key);
        decipher.start({
            iv: iv,
            tagLength: 128,
            tag: tag // authentication tag from encryption
        });

        decipher.update(encrypted);
        let pass = decipher.finish();
        // pass is false if there was a failure (eg: authentication tag didn't match)
        if(!pass) {
            return null;
        }

        return { data: decipher.output.toString('utf8') }
    }

    static hexToBuffer(hexValue) {
        let buff = Buffer.from(hexValue, 'hex');
        return forge.util.createBuffer(buff.toString('binary'));
    }

}
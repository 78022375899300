import React from 'react';
import 'semantic-ui-css/semantic.css'
import {Button, Container, Dimmer, Flag, Grid, Icon, List, Loader, Modal, Segment} from 'semantic-ui-react'
import _ from "lodash";
import PhoneNumberManager from "../utils/PhoneNumberManager";

const SECRET = "DONT_REVEAL_TO_ANYONE_2020";


export class GeneratePhoneModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            open: false,
            showError: false,
            error: '',
            phone: '',
            credits : 3
        }

        this.handleClose = this.handleClose.bind(this);
        this.handleOpen = this.handleOpen.bind(this);
        this.handleGenerateClick = this.handleGenerateClick.bind(this);

        this.handleNumberSelected = this.handleNumberSelected.bind(this);
    }

    onValueChange = (e, { name, value }) => {
        this.setState({ [name]: value });
    }

    handleOpen() {
        this.setState({
            open: true,
            showError: false,
            error: '',
            time: '',
            phone: '',
            accepted: false
        });
    }

    handleClose() {
        this.setState({
            open: false,
            error: null,
            showError: false,
            phone: '',
        })
    }

    // handleSubscribe() {
    //
    //     if (!this.state.accepted) {
    //         return;
    //     }
    //
    //     const { phone, time } = this.state;
    //
    //     if (!phone || !validator.isMobilePhone(phone.trim(), ['da-DK','pl-PL'])) {
    //         this.setState({
    //             error: 'Invalid phone number',
    //             showError: true
    //         });
    //         return;
    //     }
    //
    //     if (!time || time.trim().length === 0) {
    //         this.setState({
    //             error: 'Invalid date',
    //             showError: true
    //         });
    //         return;
    //     }
    //
    //     this.setState({
    //         showError: false,
    //         inProgress:  true
    //     });
    //
    //     Backend
    //         .subscribe({
    //             phoneNumber: phone.trim(),
    //             roadId: this.props.road.id,
    //             time: time.trim(),
    //             direction: this.props.direction === 'from' ? 0 : 1,
    //             secret: SECRET
    //         } )
    //         .then( response => {
    //             console.log("Reponse: " + JSON.stringify(response));
    //
    //             if (!response.status) {
    //                 throw "Operation failed";
    //             }
    //
    //             this.setState({
    //                 showError: false,
    //                 inProgress:  false,
    //                 showMessage: true,
    //                 message : 'You have been subscribed!'
    //             });
    //
    //             setTimeout(() => {
    //                 this.setState({
    //                     showMessage: false
    //                 });
    //                 this.handleClose();
    //             }, 2000);
    //
    //         })
    //         .catch( err => {
    //             console.log("Error: " + JSON.stringify(err));
    //             this.setState({
    //                 showMessage: false,
    //                 showError: true,
    //                 error: 'Problem has occurred. Please try again',
    //                 inProgress:  false
    //             });
    //         })
    //
    // }
    //

    handleGenerateClick() {
        this.setState({
            inProgress: true
        });

        setTimeout(() => {
            this.setState({
                inProgress: false,
                credits: 2,
                phone: "+16195676646"
            });

            if (this.props.onNumberGenerated) {
                this.props.onNumberGenerated("+16195676646");
            }

        }, 3000);

        setTimeout(() => {
           this.handleClose();
        }, 4000);

    }

    handleNumberSelected(phone) {
        if (this.props.onNumberGenerated) {
            this.props.onNumberGenerated(phone);
        }
    }

    render() {
        return (
            <Modal
                trigger={ <Button onClick={this.handleOpen} basic style={{backgroundColor: '#4FBBBC', color: '#ffffff', marginLeft: '16px', fontSize: '12px', cursor: 'pointer', textAlign: 'right'}}>Assign</Button> }
                open={this.state.open} size='tiny' >
                <Modal.Content style={{borderRadius: '0'}}>
                    <Modal.Description style={{padding: '24px', fontSize: '24px', fontWeight: '300'}}>

                        <Container textAlign='right'>
                            <Icon name='close' style={{color: '#e6e6e6', opacity: '0.6', height: '48px' }} disabled={this.state.inProgress} onClick={this.handleClose}/>
                        </Container>

                        <div style={{color: '#010101', fontWeight: '500', fontSize: '21px', marginBottom: '24px'}}>
                            Your Phone Numbers:
                        </div>

                        <PhoneList
                            onClick={this.handleNumberSelected}
                            phones={PhoneNumberManager.getAll()} />

                        {/*<div style={{ marginBottom: '24px'}}>You have <b><span style={{color: 'green', fontWeight: '500'}}>{this.state.credits} credits</span></b></div>*/}

                        {/*<div>*/}
                            {/*<Icon name='mobile alternate' size='large' color='grey' style={{margin: 'auto 16px auto 0'}}/>*/}

                            {/*{ !this.state.phone && (*/}
                                {/*<span style={{color: 'grey', fontSize: '43px'}}>_ _ _ _ _ _ _ _ _ _ _ _</span>*/}
                            {/*)}*/}

                            {/*{ this.state.phone && (*/}
                                {/*<span style={{color: '#010101', fontSize: '33px', fontWeight: '500'}}>{this.state.phone}</span>*/}
                            {/*)}*/}

                        {/*</div>*/}

                        {/*{!this.state.inProgress && (*/}
                            {/*<Button size='big' color='black' style={{width: '256px', borderRadius: '0', marginTop: '24px'}}*/}
                                    {/*disabled={this.state.inProgress}*/}
                                    {/*onClick={this.handleGenerateClick}>*/}
                                {/*Generate New Number*/}
                            {/*</Button>*/}
                        {/*)}*/}

                        {/*{this.state.inProgress && (*/}
                            {/*<Segment style={{height: '45px', width: '256px', padding: '0', boxShadow: 'none', margin: '24px auto 0 auto', borderRadius: '0', border: '0px' }}>*/}
                                {/*<Dimmer active style={{ padding: '0', borderRadius: '0', boxShadow: 'none', margin: '0 0 0 -2px', border: '0px' }}>*/}
                                    {/*<Loader/>*/}
                                {/*</Dimmer>*/}
                            {/*</Segment>*/}
                        {/*)}*/}



                        {/*{!this.state.inProgress && !this.state.showMessage && (*/}
                        {/*<div>*/}

                        {/*<Segment vertical style={{borderBottom: 'none'}}>*/}
                        {/*<Input placeholder='Mit mobilnummer' icon='phone' iconPosition='left'*/}
                        {/*style={{height: '58px', width: '90%', fontSize: '22px'}}*/}
                        {/*name='phone' value={this.state.phone} onChange={this.onValueChange}/>*/}
                        {/*</Segment>*/}

                        {/*<Segment vertical style={{borderBottom: 'none', padding: '0'}}>*/}
                        {/*<div style={{ display: 'flex', alignItems: 'center' }}*/}
                        {/*>*/}
                        {/*<span style={{marginRight: '8px', width: '30%'}}>Hver dag kl.: </span>*/}
                        {/*<TimeInput*/}
                        {/*style={{height: '32px', width: '80%'}}*/}
                        {/*name="time"*/}
                        {/*placeholder="Tid"*/}
                        {/*value={this.state.time}*/}
                        {/*iconPosition="left"*/}
                        {/*onChange={this.onValueChange}*/}
                        {/*/>*/}
                        {/*</div>*/}
                        {/*</Segment>*/}

                        {/*<Segment vertical style={{borderBottom: 'none', marginTop: '8px' }}>*/}
                        {/*<Checkbox*/}
                        {/*style={ this.state.accepted ? {} : {backgroundColor: '#ff6666', padding: '4px'}}*/}
                        {/*label='Ved at tilmelde dig accepterer du at modtage SMS fra Hillerød kommune ang. trafik på Carlsbergvej'*/}
                        {/*onChange={this.toggleAcceptance} checked={this.state.accepted}/>*/}
                        {/*</Segment>*/}

                        {/*</div>*/}

                        {/*)}*/}

                        {/*{!this.state.inProgress && this.state.showError && (*/}
                        {/*<div style={{fontWeight: '500', fontSize: '16px', color: 'red', margin: '8px 0'}}>{this.state.error}</div>*/}
                        {/*)}*/}

                        {/*{!this.state.inProgress && this.state.showMessage && (*/}
                        {/*<div style={{width: '100%', height: '96px', maxHeight: '128px', backgroundColor: 'green', opacity: '0.5',*/}
                        {/*display: 'flex', alignItems: 'center', justifyContent: 'center'}}>*/}
                        {/*<span style={{fontWeight: '500', fontSize: '16px', color: 'white', margin: '8px 0'}}>{this.state.message}</span>*/}
                        {/*</div>*/}
                        {/*)}*/}

                        {/*{this.state.inProgress && (*/}
                        {/*<div style={{width: '100%', height: '96px', maxHeight: '128px', backgroundColor: 'teal', opacity: '0.5'}}>*/}
                        {/*<Loader content='Processing ...' />*/}
                        {/*</div>*/}
                        {/*)}*/}

                    </Modal.Description>
                </Modal.Content>
            </Modal>
        );


    }

}

const PhoneList = (props) => (
    <List divided selection verticalAlign='middle'>

        { _.map(props.phones, (phone) => (
                <List.Item style={{padding: '16px'}} onClick={() => props.onClick(phone)}>
                    <Grid>
                        <Grid.Row>

                            <Grid.Column width={1} style={{display: 'flex',  justifyContent: 'center', alignItems: 'center'}}>
                                <Flag style={{paddingLeft: '24px',}} name={phone.country}/>
                            </Grid.Column>

                            <Grid.Column width={15} style={{display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
                                <span style={{margin: 'auto 32px'}}>{phone.number}</span>
                            </Grid.Column>

                        </Grid.Row>
                    </Grid>
                </List.Item>
            )
        )}


        {/*<List.Item style={{display: 'flex', padding: '16px'}}>*/}
        {/*<Flag name='us' style={{marginRight: '64px'}}/>*/}
        {/*<List.Content>*/}
        {/*<List.Header as='a'>+1 34 234 12 34</List.Header>*/}
        {/*</List.Content>*/}
        {/*</List.Item>*/}
    </List>
)
import React from "react";
import 'semantic-ui-css/semantic.css';
import {Button, Container, Dimmer, Dropdown, Grid, Icon, Label, Loader, Message, Progress} from "semantic-ui-react";
import Vault from "../Vault";
import _ from 'lodash';
import './MessageReader.css'
import Mailbox from "../utils/Mailbox";
import './MailboxView.css';
import MailList from './MailList';
import MailBody from './MailBody';
import GlobalContext from "./context/GlobalContext";


export class MailboxView extends React.Component {

    static contextType = GlobalContext;

    constructor(props) {
        super(props);

        this.state = {
            id : '',
            inProgress: false,
            mailLoaded: false, //(!props.match.params.id),
            width: window.innerWidth,
            currentIdentity: 'test',
            selectedMailId: null,
            selectedMailLoading: false,
            showDangerousMail: false,
            currentMailBodyText: 'init message | EMPTY',
            mailListsAnyLoaded: false,
            mailListsError: null,
            lastUpdateDate: null
        }

        this.onIdentityChange = this.onIdentityChange.bind(this);
        this.handleMailClick = this.handleMailClick.bind(this);
        this.initMailboxView = this.initMailboxView.bind(this);
        this.loadMailLists = this.loadMailLists.bind(this);
        this.refresh = this.refresh.bind(this);
        this.handleBackToMailboxClick = this.handleBackToMailboxClick.bind(this);
        this.prepareSummaryList = this.prepareSummaryList.bind(this);

    }

    componentDidMount() {
        if (this.context.mails === undefined) {
            this.loadMailLists();
        }
    }

    loadMailLists() {
        this.context.updateMailLoader(true, 0);

        Mailbox
            .loadAll((success, finished, total, allMailSummaryById, newMailAvailable) => {
                this.context.updateMails(allMailSummaryById);
                this.initMailboxView(success, finished, total, allMailSummaryById)
            })
            .catch(err => {
                console.error("Loading mails ERROR 3: " + err);
                this.context.updateMailLoader(false, 0);
                this.setState({
                    mailListsError: 'Problem with loading mails'
                });
            })
    }

    refresh() {
        this.context.updateMailLoader(true, 0);

        Mailbox
            .refresh((success, finished, total, allMailSummaryById, newMailAvailable) => {
                this.context.updateMails(allMailSummaryById);
                this.initMailboxView(success, finished, total, allMailSummaryById)
            })
            .catch(err => {
                console.error("Loading mails ERROR2: " + err);
                this.context.updateMailLoader(false, 0);
                this.setState({
                    mailListsError: 'Problem with loading mails'
                });
            })
    }

    initMailboxView(success, finished, total, summaryListsById) {

        if (!summaryListsById || summaryListsById.length === 0) {
            this.context.updateMailLoader(finished < total, finished/total*100);

            this.setState({
                mailListsAnyLoaded: true,
                mailListsError: !success
            });
            return;
        }

        this.context.updateMailLoader(finished < total, finished/total*100);
        this.setState({
            mailListsAnyLoaded: true,
            mailListsError: !success
        });

    }

    onValueChange = (e, { name, value }) => {
        this.setState({ [name]: value });
    }

    onIdentityChange(e, {value, options})  {
        this.setState({
            currentIdentityId: value,
            selectedMailLoading: true,
            selectedMailId: null,
            showDangerousMail: false
        })
    }

    handleMailClick = (id) => () => {

        this.setState({
            selectedMailLoading: true,
            selectedMailId: id,
            showDangerousMail: false,
        });

        Mailbox
            .getMail(id, this.state.currentIdentityId, (mails) => this.context.updateMails(mails))
            .then(mail => {
                if (this.state.selectedMailId === id) {
                    this.setState({
                        selectedMail: mail,
                        selectedMailBodyText: mail.text,
                        selectedMailBodyHtml: mail.html,
                        selectedMailLoading: false,
                        selectedMailLoaded: true,
                        selectedMailError: null,
                    });
                }
            })
            .catch(err => {
                console.error("Mail read/decrypt error", err);
                this.setState({
                    selectedMailBodyText : null,
                    selectedMailBodyHtml : null,
                    selectedMailLoading: false,
                    selectedMailLoaded: true,
                    selectedMailError: "Something has gone wrong. Please try again"
                });
            });
    }

    handleBackToMailboxClick = () => {
        this.setState({
            selectedMailLoading: false,
            selectedMailId: null,
            showDangerousMail: false
        });
    }

    prepareSummaryList() {
        const mails = this.context.mails || [];

        let summaries;
        if (this.state.currentIdentityId) {
            summaries = mails[this.state.currentIdentityId];
        } else {
            summaries = _.flatMapDeep(Object.values(mails));
        }

        return _.orderBy(summaries, ["date"], ["desc"])
    }

    mapIdentityOptions(summaryListsById) {
        let identityOptions = _.filter(Vault.getAllIdentity(), id => summaryListsById[id.id] && summaryListsById[id.id].length > 0);
        return _.map(identityOptions, id => {

            let n = summaryListsById[id.id] ?
                summaryListsById[id.id]
                    .filter(summaryList => !summaryList.alreadyRead)
                    .length : 0;
            let text = id.note + " | " + id.domain;

            return {
                key: id.id,
                text: text,
                value: id.id,
                content:  <div><Label horizontal><Icon name='envelope outline'></Icon>{n}</Label>{text}</div>
            }
        });
    }

    render() {

        //TODO: problems can occur when multiple Identities will have the same note+domain. FIX Dropdown element to handle this
        const identityOptions = this.mapIdentityOptions(this.context.mails || []);

        return (
            <div className='mailbox-content'>

                    <div>

                        <div className='mail-list-header'>

                            <Grid stackable style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: "-1.3rem"}}>

                                <Grid.Column mobile={16} tablet={1} computer={1} className='mail-list-header-icon-wrapper'>
                                    <Icon name='user' size='big'/>
                                </Grid.Column>

                                <Grid.Column mobile={16} tablet={15} computer={13}>
                                    <Dropdown
                                        placeholder='Filter by Identity'
                                        fluid
                                        search
                                        selection
                                        clearable
                                        options={identityOptions}
                                        style={{width: '100%', fontSize: '17px', border: 'none'}}
                                        value={this.state.currentIdentityId}
                                        onChange={this.onIdentityChange}
                                    />
                                </Grid.Column>

                                <Grid.Column mobile={16} tablet={16} computer={2} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <Button icon size='big' onClick={this.refresh} style={{backgroundColor: '#4FBBBC', width: '100%'}}>
                                        <Icon style={{color: 'white'}} name='refresh'/>
                                    </Button>
                                </Grid.Column>

                            </Grid>

                        </div>

                        <div style={{marginBottom: '32px'}}>

                            { this.context.loaders.mail && (
                                <div style={{marginBottom: '64px'}}>
                                    <Progress percent={this.context.loaders.mailProgress} active>
                                        To keep your privacy, no.ID downloads emails in random order with random intervals
                                    </Progress>
                                </div>
                            )}

                            <MailList
                                summaries={this.prepareSummaryList()}
                                currentIdentityId={this.state.currentIdentityId}
                                selected={this.state.selectedMailId}
                                onClick={this.handleMailClick}/>

                        </div>

                        { !this.state.showDangerousMail && this.state.selectedMailId && !this.state.selectedMailError && !this.state.selectedMailLoading && this.state.selectedMailLoaded && (
                            <Container className='message-body-info-wrapper' textAlign='right'>
                                <div>
                                    <div className='message-body-info-text'>
                                        Images and other elements can contain tracking tags so they are stripped off here.
                                    </div>
                                    <Button className='btn-mail-body-action' size='tiny' style={{marginLeft: '24px'}}
                                            onClick={() => {this.setState({showDangerousMail: true})}} >
                                        Display Original Message
                                    </Button>
                                </div>
                            </Container>
                        )}


                        {this.state.selectedMailId && (
                            <Container style={{marginTop: '0px', paddingBottom: '48px', width: '100%', marginLeft: '0px !important', marginRight: '0px !important'}}>

                                {this.state.selectedMailLoading && (
                                    <Dimmer active inverted style={{position: 'relative'}}>
                                        <Loader inverted>Loading...</Loader>
                                    </Dimmer>
                                )}

                                {!this.state.selectedMailError && !this.state.selectedMailLoading && this.state.selectedMailLoaded && (
                                    <MailBody mail={this.state.selectedMail}
                                              text={this.state.selectedMailBodyText}
                                              html={this.state.selectedMailBodyHtml}
                                              dangerousMail={this.state.showDangerousMail}

                                    />
                                )}

                                {this.state.selectedMailError && !this.state.selectedMailLoading && (
                                    <Message negative>
                                        <Message.Header>{this.state.selectedMailError}</Message.Header>
                                    </Message>
                                )}



                            </Container>
                        )}

                    </div>

                { this.state.mailListsError && (
                    <div style={{margin: '84px auto'}}>
                        <Message
                            error
                            header='There was some errors'
                            content='Please try again by clicking refresh button'
                        />

                        <Button size='big' onClick={this.refresh} className='refresh-button-error'>
                            <Icon style={{color: 'white'}} name='refresh'/>
                            Refresh
                        </Button>

                    </div>
                )}


            </div>
        )
    };

}
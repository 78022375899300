import React from "react";
import {Button, Dropdown, Grid, Icon} from "semantic-ui-react";
import './BuyPhoneNumberView.css';
import _ from "lodash";
import PhoneNumberManager from "../utils/PhoneNumberManager";
import PulseLoader from "../../node_modules/react-spinners/PulseLoader";
import {RecognitionView} from "./RecognitionView";
import Vault from "../Vault";

export class BuyPhoneNumberView extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            createIdInProgress: false,
            showMessage: false,
            showError: false,
            error: 'Error occurred, please try again.',
            realIdVerificationNeeded: false
        }

        this.onCountryChange = this.onCountryChange.bind(this);
        this.handleBuyPhoneClick = this.handleBuyPhoneClick.bind(this);
        this.onTypeChange = this.onTypeChange.bind(this);
        this.showBuyPhoneNumberMobile = this.showBuyPhoneNumberMobile.bind(this);
    }

    onValueChange = (e, { name, value }) => {
        this.setState({ [name]: value });
    }

    onCountryChange(e, {value, options})  {
        let buyPhoneMeta = _.find(options, (o) => { return o.value === value });
        this.setState({
            buyPhoneMeta,
            realIdVerificationNeeded : buyPhoneMeta.needRealIdVerification
        });
    }

    onTypeChange(e, {value, options})  {

    }

    handleBuyPhoneClick() {

        this.setState({
            buyPhoneInProgress: true,
            buyPhoneError: undefined
        });

        PhoneNumberManager.getNew(this.state.buyPhoneMeta.value)
            .then(newPhone => {

                if (this.props.onBuyPhoneSuccess) {
                    this.props.onBuyPhoneSuccess(newPhone, this.state.buyPhoneMeta.cost);
                }

                this.setState(prevState => ({
                    buyPhoneInProgress: false,
                    buyPhoneError: undefined,
                    // totalCredit: prevState.totalCredit - prevState.buyPhoneMeta.cost,
                    showError: false,
                    showMessage: true,
                    error: null,
                    buyPhoneMeta: null
                }));


                setTimeout(() => { this.setState({ showMessage: false })}, 2500);

            })
            .catch(err => {
                console.log("BuyPhoneNumberView | getNew | err: " + err);
                this.setState({
                    buyPhoneInProgress: false,
                    showError: true,
                    buyPhoneMeta: null,
                    error: "Error occurred, please try again."
                });

                setTimeout(() => { this.setState({ showError: false })}, 2500)
            });
    }

    showBuyPhoneNumberMobile(event) {
        event.stopPropagation();
        this.props.showBuyPhoneNumberMobile(false);
    }

    isUserRealIdVerified() {
        return Vault.recognitionId;
    }

    render() {

        return (

            <div>

                <div className='buy-phone-number' style={{padding: '0px !important'}}>

                    <div className='buy-phone-number-header'>New Phone Number</div>

                    <div className='buy-phone-number-subheader'>
                        Your privacy is protected. <br className="mobile-break"/>
                        Phones are registered to no.ID ehf.
                    </div>

                    {!this.state.showError && !this.state.showMessage && (

                        <Grid stackable doubling columns='equal' style={{marginTop: '16px'}}>
                            <Grid.Column className='country-column'>

                                <div className='country-label'>
                                    Country:
                                </div>

                                <CountryDropdown
                                    disabled={this.state.buyPhoneInProgress}
                                    onChange={this.onCountryChange}/>
                            </Grid.Column>

                            {/*<Grid.Column className='type-column'>*/}

                                {/*<div className='country-label'>*/}
                                    {/*Type:*/}
                                {/*</div>*/}

                                {/*<TypeDropdown*/}
                                    {/*disabled={this.state.buyPhoneInProgress}*/}
                                    {/*onChange={this.onTypeChange}/>*/}
                            {/*</Grid.Column>*/}

                            <Grid.Column className='cost-wrapper cost-column'>
                                <div className='cost-label'>Cost:</div>

                                { this.state.buyPhoneMeta && (
                                    <div className='cost-value'>
                                        {this.state.buyPhoneMeta.cost} <span style={{fontSize: '11px'}}>credits / month</span>
                                    </div>
                                )}

                                { !this.state.buyPhoneMeta && (
                                    <span className='cost-value' style={{color: 'grey', fontWeight: '700'}}>0</span>
                                )}

                            </Grid.Column>

                            { this.state.realIdVerificationNeeded && (
                                <Grid.Column only='mobile'>
                                    <RecognitionView/>
                                </Grid.Column>
                            )}

                            <Grid.Column className='buy-column'>

                                <Button disabled={!this.state.buyPhoneMeta || this.state.buyPhoneInProgress ||
                                (this.state.buyPhoneMeta.needRealIdVerification && !this.isUserRealIdVerified())}
                                        onClick={this.handleBuyPhoneClick}
                                        className='btn-buy'
                                >

                                    {!this.state.buyPhoneInProgress && (

                                        <div>
                                            <Icon style={{ color: '#ffffff', fontSize: '16px', marginRight: '16px'}} name='shopping cart'/>
                                            <span style={{ }}>Buy Number</span>
                                        </div>

                                    )}

                                    {this.state.buyPhoneInProgress && (

                                        <PulseLoader
                                            size={10}
                                            color={"#ffffff"}
                                            loading={true}
                                        />

                                    )}

                                </Button>


                            </Grid.Column>

                            <Grid.Column only='mobile' style={{ padding: '0px',display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <Button className='btn-dont-buy' onClick={this.showBuyPhoneNumberMobile}
                                        disabled={this.state.buyPhoneInProgress}
                                >
                                    I don't want to buy now
                                </Button>
                            </Grid.Column>

                        </Grid>

                    )}

                    { this.state.showMessage && (
                        <div className='buy-phone-message-wrapper'>
                            <div className='buy-phone-success' >
                                {/*<div style={{maxWidth: '200px', display: 'flex', alignItems: 'center'}}>*/}
                                <Icon name='checkmark' style={{marginRight: '16px'}}/>
                                {/*<span>Great, your new number is ready</span>*/}
                                <span>Your new number is ready</span>
                                {/*</div>*/}
                            </div>
                        </div>
                    )}

                    { this.state.showError && (
                        <div className='buy-phone-message-wrapper'>
                            <div className='buy-phone-error'>
                                <Icon name='warning circle' style={{marginRight: '16px'}}/>
                                {this.state.error}
                            </div>
                        </div>
                    )}

                </div>

                { this.state.realIdVerificationNeeded && (
                    <Grid>
                        <Grid.Column only="tablet computer">
                            <RecognitionView/>
                        </Grid.Column>
                    </Grid>
                )}

            </div>

        );
    }

}

const countryOptions = [
    // { key: 'au', value: 'au', flag: 'au', text: 'Australia', cost: '15' },
    // { key: 'at', value: 'at', flag: 'at', text: 'Austria', cost: '4' },
    // { key: 'be', value: 'be', flag: 'be', text: 'Belgium', cost: '2' },
    // { key: 'br', value: 'br', flag: 'br', text: 'Brazil', cost: '2' },
    // { key: 'ca', value: 'ca', flag: 'ca', text: 'Canada', cost: '2' },
    // { key: 'cl', value: 'cl', flag: 'cl', text: 'Chile', cost: '2' },
    // { key: 'ck', value: 'ck', flag: 'ck', text: 'Cook Islands', cost: '2' },
    // { key: 'hr', value: 'hr', flag: 'hr', text: 'Croatia', cost: '2' },
    // { key: 'cy', value: 'cy', flag: 'cy', text: 'Cyprus', cost: '2' },
    // { key: 'cz', value: 'cz', flag: 'cz', text: 'Czech Republic', cost: '2' },
    // { key: 'dk', value: 'dk', flag: 'dk', text: 'Denmark', cost: '2' },
    // { key: 'ee', value: 'ee', flag: 'ee', text: 'Estonia', cost: '2' },
    // { key: 'fi', value: 'fi', flag: 'fi', text: 'Finland', cost: '2' },
    // { key: 'fr', value: 'fr', flag: 'fr', text: 'France', cost: '2' },
    // { key: 'gr', value: 'gr', flag: 'gr', text: 'Greece', cost: '2' },
    // { key: 'ie', value: 'ie', flag: 'ie', text: 'Ireland', cost: '2' },
    // { key: 'il', value: 'il', flag: 'il', text: 'Israel', cost: '2' },
    // { key: 'it', value: 'it', flag: 'it', text: 'Italy', cost: '2' },
    // { key: 'lv', value: 'lv', flag: 'lv', text: 'Latvia', cost: '2' },
    // { key: 'my', value: 'my', flag: 'my', text: 'Malaysia', cost: '2' },
    // { key: 'mx', value: 'mx', flag: 'mx', text: 'Mexico', cost: '2' },
    // { key: 'nl', value: 'nl', flag: 'nl', text: 'Netherlands', cost: '2' },
    // { key: 'nz', value: 'nz', flag: 'nz', text: 'New Zealand', cost: '2' },
    // { key: 'pl', value: 'pl', flag: 'pl', text: 'Poland', cost: '4' },
    // { key: 'pt', value: 'pt', flag: 'pt', text: 'Portugal', cost: '2' },
    // { key: 'ro', value: 'ro', flag: 'ro', text: 'Romania', cost: '2' },
    // { key: 'ru', value: 'ru', flag: 'ru', text: 'Russia', cost: '2', needRealIdVerification: true },
    // { key: 'se', value: 'se', flag: 'se', text: 'Sweden', cost: '2' },
    // { key: 'ch', value: 'ch', flag: 'ch', text: 'Switzerland', cost: '2' },
    { key: 'gb', value: 'gb', flag: 'uk', text: 'United Kingdom', cost: '2', needRealIdVerification: false },
    { key: 'us', value: 'us', flag: 'us', text: 'United States', cost: '2', needRealIdVerification: false },
    // { key: 'uy', value: 'uy', flag: 'uy', text: 'Uruguay', cost: '1' },
    { key: 'is', value: 'is', flag: 'is', text: 'Iceland', cost: '10', needRealIdVerification: false },

];

const typeOptions = [
    { key: 'Local', value: 'Local', text: 'Local' }
];

const CountryDropdown = (props) => (
    <Dropdown
        placeholder='Select'
        fluid
        search
        selection
        disabled={props.disabled}
        options={countryOptions}
        className='buy-dropdown'
        onChange={props.onChange}
    />
);

const TypeDropdown = (props) => (
    <Dropdown
        placeholder='Select'
        fluid
        search
        selection
        disabled={props.disabled}
        options={typeOptions}
        className='buy-dropdown'
        onChange={props.onChange}
    />
);


import { Subject } from 'rxjs';

class IncomingCallManager {

    incomingCallSubject = new Subject();
    activeCall = null;

    getObservable() {
        return this.incomingCallSubject;
    }

    setNewActiveCall(incomingCall) {

        if (this.activeCall) {
            //By default - reject. Later we'll implement multiple calls handling
            if (incomingCall) {
                incomingCall.reject();
            }
            return;
        }

        this.activeCall = incomingCall;

        this.incomingCallSubject.next( { type: 'newCall'} );
    }

    getActiveCall() {
        return this.activeCall;
    }

    reject() {
        if (this.activeCall) {
            this.activeCall.reject();
            this.activeCall = null;
        }
    }

    accept() {
        if (this.activeCall) {
            this.activeCall.accept();
        }
    }

    hangUp() {
        if (this.activeCall) {
            this.activeCall.disconnect();
            this.activeCall = null;
        }
    }

    disconnect(call) {
        if (this.activeCall == call) {
            this.incomingCallSubject.next( { type: 'disconnect'} );
            return;
        }

        if (call) {
            call.disconnect();
        }
    }

    reportError(call, error) {
        if (this.activeCall == call) {
            console.log("IncomingCallManager | error reported for active call")
            this.incomingCallSubject.error(error);
        }
    }

    cancel(call) {
        if (this.activeCall == call) {
            this.incomingCallSubject.next( { type: 'cancel'} );
        }
    }

}

export default IncomingCallManager = new IncomingCallManager();
import Backend from "../Backend";
import Vault from "../Vault"
import Auth from "../Auth";
import moment from "moment";
import _ from "lodash";
import LRU from "lru-cache";
import GlobalContext from "../components/context/GlobalContext";
import {asyncForEach} from "./PromiseUtils";

export default class SmsBox {

    static contextType = GlobalContext;

    static lastUpdateDate;
    static allSmsSummaryById = {};
    static cache = new LRU({
        max: 30,
        length: function (n, key) { return n * 2 + key.length },
        maxAge: 1000 * 60 * 60
    });

    static MIN_TIMEOUT = 2000;
    static MAX_TIMEOUT = 6000;

    static async loadAll(cb, forced) {

        const date = moment.utc();

        if (!forced &&
            (this.lastUpdateDate && (!date && !this.lastUpdateDate.isBefore(moment(date).subtract(5, 'minutes'))))) {
            return cb([], Vault.getAllPhonesIds(), this.allSmsSummaryById);
        }

        const params = {
            sessionToken : Auth.sessionToken,
            lastUpdateDate: this.lastUpdateDate ? this.lastUpdateDate.format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS) : undefined
        };

        let failedPhoneIds = [];
        let successPhoneIds = [];

        await asyncForEach(
            Vault.getAllPhonesIds()
            .sort( () => Math.random() - 0.5),
            async (id, index) => {
                await this.getSmsSummaryRequest({...params, id: id}, index)
                    .then(async (response) => {

                        if (!response || !response.data || !response.data.status) {
                            failedPhoneIds.push(id);
                            return cb(failedPhoneIds, successPhoneIds, this.allSmsSummaryById);
                        }

                        let summariesById = await this.decryptSummaryList(response.data.data);
                        await this.merge(summariesById, date);

                        successPhoneIds.push(id);
                        return cb(failedPhoneIds, successPhoneIds, this.allSmsSummaryById, this.checkForNewSMS(summariesById));
                    })
                    .catch(err => {
                        console.log("Error when loading sms lists : " + err);
                        failedPhoneIds.push(id);
                        return cb(failedPhoneIds, successPhoneIds, this.allSmsSummaryById);
                    });
            });

        if (failedPhoneIds.length === 0) {
            this.lastUpdateDate = date;
        }
    }

    static async getSmsSummaryRequest(params, index) {
        await new Promise(resolve => setTimeout(resolve, index > 0 ? Math.floor(Math.random() * (this.MAX_TIMEOUT - this.MIN_TIMEOUT)) + this.MIN_TIMEOUT : 0));
        return Auth.isLoggedIn ? Backend.getAllSmsSummary(params) : Promise.reject("User logged out");
    }

    static async decryptSummaryList(allSummaryLists) {

        let summariesById = _.groupBy(allSummaryLists, s => s.phoneId);

        for (let id in summariesById) {

            let encryptedSummaries = summariesById[id];
            const phone = Vault.getPhoneById(id);
            let plainSummaries = [];

            for (let idx = 0; idx < encryptedSummaries.length; idx++) {
                let s = encryptedSummaries[idx];

                await Vault.pgpDecrypt(s.encryptedSummary, phone.keys.public, phone.keys.private, phone.keys.keyPassword)
                    .then(jsonSummary => {

                        let summary = JSON.parse(jsonSummary);
                        summary.smsId = s.id;
                        summary.isCompromised = s.isCompromised;

                        plainSummaries.push(summary);
                    })
                    .catch(err => {
                        console.error("Problem with decoding esms summary ", err);
                    });
            }

            console.log("plainSummaries.size = " + plainSummaries.length);
            console.log("plainSummaries: " + JSON.stringify(plainSummaries, null, 3));

            summariesById[id] = plainSummaries;
        }

        return summariesById;
    }

    static async merge(newSmsSummaries, updateDate) {

        if ((!this.lastUpdateDate || (this.lastUpdateDate && this.lastUpdateDate.isBefore(updateDate))) &&
            newSmsSummaries) {

            for(let phone in newSmsSummaries) {
                if (!this.allSmsSummaryById[phone]) {
                    this.allSmsSummaryById[phone] = [];
                }
                let merged = _.concat(newSmsSummaries[phone], this.allSmsSummaryById[phone]);
                this.allSmsSummaryById[phone] = _.uniqBy(merged, 'smsId');
            }
        }

    }

    static checkForNewSMS(summariesById) {

        let newSmsPhoneIds = new Set();

        for(let identity in summariesById) {

            if (summariesById[identity] !== null) {
                summariesById[identity].forEach(newSummary => {
                    if (moment(newSummary.date).utc().isAfter(Auth.loginDate.utc())) {
                        newSmsPhoneIds.add(identity);
                    }
                });
            }
        }

        return newSmsPhoneIds;
    }

    static async refresh(cb) {
        return this.loadAll(cb, true);
    }

}

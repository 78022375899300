import React, {Component} from 'react';
import './App.css';
import {IdentityDashboard} from "./components/IdentityDashboard";
import {Redirect, Route, Switch} from 'react-router-dom'
import {Login} from "./components/Login";
import Auth from "./Auth";
import {SignUp} from "./components/SignUp";
import {MessageReader} from "./components/MessageReader";
import {Mixpanel} from "./utils/Mixpanel";
import {PhoneDashboard} from "./components/PhoneDashboard";
import {Menu} from "./components/Menu";
import SmsBox from "./utils/SmsBox";
import GlobalContext from "./components/context/GlobalContext";
import Mailbox from "./utils/Mailbox";
import {RecognitionDashboard} from "./components/RecognitionDashboard";
import Vault from "./Vault";
import {TwilioDevice} from "./utils/TwilioDevice";
import {PickUpCallModal} from "./components/PickUpCallModal";

export default class App extends Component {

    static contextType = GlobalContext;

    constructor(props) {
        super(props);
        this.state = {authStatus: this.props.authStatus || false}

        this.handleWindowClose = this.handleWindowClose.bind(this);
        this.startDefaultDataRefresher = this.startDefaultDataRefresher.bind(this);
        this.stopDefaultDataRefresher = this.stopDefaultDataRefresher.bind(this);

        // this.handleMessageClick = this.handleMessageClick.bind(this);

        this.mailRefreshInterval = null;
        this.smsRefreshInterval = null;
    }

    handleWindowClose = async (e) => {
        e.preventDefault();
    }

    componentDidMount() {
        Mixpanel.track('Page Visit');
    }

    componentWillMount() {
        //window.addEventListener('beforeunload', this.handleWindowClose);

        this.validateUserSession();

        this.startDefaultDataRefresher();
    }

    componentWillUnmount() {
        //window.removeEventListener('beforeunload', this.handleWindowClose);

        this.stopDefaultDataRefresher();

        TwilioDevice.destroyAllDevices();
    }

    startDefaultDataRefresher() {

        const mailsRefreshInterval = Math.max(Vault.getAllIdentityIds().length * 7000, 60000);
        const smsRefreshInterval = Math.max(Vault.getAllPhonesIds().length * 7000, 60000);

        this.mailRefreshInterval = setInterval(() => {
            if (Auth.isLoggedIn) {
                Mailbox.refresh((success, finished, total, allMailSummaryById, newEmailAvailable) => {
                        if (newEmailAvailable !== undefined) {
                            this.context.updateMails(allMailSummaryById);
                        }
                    })
                    .catch(err => {
                        //DO nothing
                    })

            }
        }, mailsRefreshInterval);

        this.smsRefreshInterval = setInterval(() => {
            if (Auth.isLoggedIn) {
                SmsBox.refresh((failedPhoneIds, successPhoneIds, allSmsSummaryById, newSmsPhoneIds) => {
                    if (newSmsPhoneIds.size > 0) {
                        this.context.updateSmsMessages(allSmsSummaryById);
                        this.context.updateNewSmsPhoneIds(newSmsPhoneIds);
                    }
                })
                    .catch(err => {
                        //DO nothing
                    })

            }
        }, smsRefreshInterval);
    }

    stopDefaultDataRefresher() {

        if (this.mailRefreshInterval) {
            clearInterval(this.mailRefreshInterval);
        }
        if (this.smsRefrshInterval) {
            clearInterval(this.smsRefreshInterval);
        }

    }

    validateUserSession() {
        let checkIfLoggedIn = sessionStorage.getItem('loggedIn');
        if(checkIfLoggedIn === 'true'){
            this.setState({
                authStatus: true
            })
        } else {
            this.setState({
                authStatus: false
            })
        }
    }

    render() {
        return (
            <div style={{flex: "1 0 auto"}}>

                { Auth.isLoggedIn && (
                    <React.Fragment>
                        <Menu history={this.props.history} />
                        <PickUpCallModal/>
                    </React.Fragment>
                )}

                <Switch>

                    <ProtectedRoute path='/main' exact component={IdentityDashboard}/>
                    <ProtectedRoute path='/message' exact component={MessageReader}/>
                    <ProtectedRoute path='/message/:id' exact component={MessageReader}/>
                    <ProtectedRoute path='/phones' exact component={PhoneDashboard}/>
                    <ProtectedRoute path='/recognition' exact component={RecognitionDashboard}/>


                    <Route path={"/"} exact component={Login} />
                    <Route path={"/login"} component={Login}/>
                    <Route path={"/register"} component={SignUp}/>
                    <Route render={() => (<Redirect to="/" />)} />
                </Switch>

            </div>
        );
    }
}

const ProtectedRoute = ({ component: Component, ...rest }) => (

    <Route {...rest} render={(props) => (
        Auth.isLoggedIn === true ?
         //   <Component {...props} /> : <Redirect to={{ pathname: '/login', state: { from: '/' }}} />
         <Component {...props} /> : <Redirect to={{ pathname: '/login', state: { from: props.location }}} />
    )} />

);

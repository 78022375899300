import Auth from "../Auth";
import Backend from "../Backend";
import PhoneNumberManager from "./PhoneNumberManager";
import IncomingCallManager from "./IncomingCallManager";

const Twilio = require('twilio-client');

let twilioDevices = [];

export class TwilioDevice {

    static startTwilioDevice(clientId) {

        if (!clientId) {
            return;
        }

        const payload = {
            sessionToken: Auth.personalisedSessionToken,
            clientId
        };

        Backend.getIncomingCallToken(payload)
            .then(response => {
                if (!response || !response.status || !response.token) {
                    console.log("Cannot get incoming call token");
                    return;
                }

                let device = new Twilio.Device(response.token, {
                    sounds: {
                        incoming: '/sounds/ringing_sound.mp3',
                    }
                });
                this.setupDevice(device);
                twilioDevices.push(device);
            })
    }

    static startTwilioDevices() {

        PhoneNumberManager.getAllClientIds()
            .filter(clientId => clientId !== undefined)
            .forEach(clientId => {
                this.startTwilioDevice(clientId);
            });
    }

    static destroyAllDevices() {
        twilioDevices.forEach(device => device.destroy());
    }

    static setupDevice(device) {

        device.on('incoming', function (conn) {
            IncomingCallManager.setNewActiveCall(conn);
        });

        device.on('cancel', function (conn) {
            IncomingCallManager.cancel(conn)
        });

        device.on('disconnect', function (conn) {
            console.log("TwilioDevice | DISCONNECT event");
            IncomingCallManager.disconnect(conn);
        });

        device.on('error', function (error) {

            if (error && IncomingCallManager.getActiveCall() == error.connection) {
                IncomingCallManager.reportError(error.connection, "An error occurred while incoming call");
                return;
            }

            console.log("An error occurred for NOT ACTIVE incoming call");
            try {
                error.connection.disconnect();
            } catch (err) {
                console.error("Error when closing incorrect connection : " + err);
            }

        });
    }

}
import React from "react";
import {Icon, Image, Label} from "semantic-ui-react";
import Auth from "../Auth";
import "./Menu.css"
import GlobalContext from "./context/GlobalContext";

export class Menu extends React.Component {

    static contextType = GlobalContext;

    constructor(props) {
        super(props);

        this.state = {
            active: 'main',
            isMenuVisible: false,
        }

        this.handleClick = this.handleClick.bind(this);
        this.toggleMenu = this.toggleMenu.bind(this);
    }

    handleClick = name => () => {
        // Mixpanel.track("Menu clicked: " + name);
        this.setState({
            active: name,
            isMenuVisible: false
        });

        if(name == 'phones') {
            this.context.updateNewSmsFlag(false);
        }
        if(name == 'message') {
            // do nothing
        }

        this.props.history.push('/' + name);
    }

    toggleMenu = () => {
        this.setState((prevState) => {
            return { isMenuVisible : !prevState.isMenuVisible }
        })
    }

    //XXX:
    logout() {
        window.location.reload();
    }

    render() {
        const unreadMessagesCount = this.context.countUnreadMessages();
        return (

            <div>

                <div className='menu'>

                    <div style={{background: 'rgba(247, 98, 98, 0.7)', margin: '5%', borderRadius: '3px',
                        color: 'white', fontSize: '12px', textTransform: 'none', padding: '12px'}}>
                        <h1 style={{fontSize: '16px'}}>
                            <Icon name='bullhorn' />
                            It's Alpha version
                        </h1>
                        <div style={{fontWeight: '300'}}>
                            The app may contain bugs or can stop working correctly.
                            If you don't agree, please leave now.
                        </div>
                        <div style={{fontWeight: '500', marginTop: '4px'}}>Don't use it for any sensitive data.</div>
                    </div>

                    <div style={{color: 'black', margin: "48px auto 64px"}}>
                        <Image src='/images/logo2.png' style={{ width: '50%', margin: '0 auto'}}/>
                    </div>

                    <div
                        className={this.state.active === 'main' ? 'menu-item-active' : 'menu-item'}
                        disabled = {this.state.active === 'main'}
                        onClick={this.handleClick('main')}>
                        <Icon name='id card outline' style={{ marginRight: '12px'}} />
                        Identities
                    </div>

                    <div
                        className={this.state.active === 'message' ? 'menu-item-active' : 'menu-item'}
                        disabled = {this.state.active === 'message'}
                        onClick={this.handleClick('message')}>
                        <Icon name='envelope outline' style={{marginRight: '12px'}} />
                        Messages
                        {unreadMessagesCount > 0 && (
                            <Label size='mini' style={{backgroundColor: '#f76262', color: '#fefefe', marginLeft: '4px'}}>New {unreadMessagesCount}</Label>
                        )}
                    </div>

                    <div
                        className={this.state.active === 'phones' ? 'menu-item-active' : 'menu-item'}
                        disabled = {this.state.active === 'phones'}
                        onClick={this.handleClick('phones')}>
                        <Icon name='phone' style={{marginRight: '12px'}} />
                        Phone Numbers
                        {this.context.flags.hasNewSms && (
                            <Label size='mini' style={{backgroundColor: '#f76262', color: '#fefefe', marginLeft: '4px'}}>SMS</Label>
                        )}
                    </div>

                    <div onClick={this.logout} style={{cursor: 'pointer'}}>
                        <div className='menu-item' >
                            <Icon name='power off' style={{ marginRight: '12px'}} />
                            Log Out
                        </div>
                        {/*<span style={{position: 'absolute', color: '#343434', fontSize: '14px', marginLeft: '30px', marginTop: '-4px', lineHeight: '1', fontWeight: '300', opacity: '0.8', overflowWrap: 'anywhere', textTransform: 'unset'}}>*/}
                           {/*{Auth.userProfile.username}*/}
                        {/*</span>*/}
                    </div>

                </div>

                <div className='menu-mobile'>

                    <div style={{position: 'relative', width: '100%', height: '62px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <Image size='tiny' src='/images/logo-small.png' style={{ margin: '0 auto' }}/>
                        <Icon size='big' style={{position: 'absolute', top: '30%', left: '24px'}}
                              name={this.state.isMenuVisible ? 'close' : 'bars'}
                              onClick={this.toggleMenu}/>
                    </div>

                    <div style={{background: 'rgba(247, 98, 98, 0.7)', margin: '5%', borderRadius: '3px',
                        color: 'white', fontSize: '12px', textTransform: 'none', padding: '12px'}}>
                        <h1 style={{fontSize: '16px'}}>
                            <Icon name='bullhorn' />
                            It's Alpha version
                        </h1>
                        <div style={{fontWeight: '300'}}>
                            The app may contain bugs or can stop working correctly.
                            If you don't agree, please leave now.
                        </div>
                        <div style={{fontWeight: '500', marginTop: '4px'}}>Don't use it for any sensitive data.</div>
                    </div>

                    {this.state.isMenuVisible && (
                        <div className='menu-mobile-content'>

                            <div
                                className={this.state.active === 'main' ? 'menu-item-mobile-active' : 'menu-item-mobile'}
                                disabled = {this.state.active === 'main'}
                                onClick={this.handleClick('main')}>
                                <Icon name='id card outline' style={{ marginRight: '24px'}} />
                                <span>Identities</span>
                            </div>

                            <div
                                className={this.state.active === 'message' ? 'menu-item-mobile-active' : 'menu-item-mobile'}
                                disabled = {this.state.active === 'message'}
                                onClick={this.handleClick('message')}>
                                <Icon name='envelope outline' style={{marginRight: '24px'}} />
                                <span>Messages</span>
                            </div>

                            <div
                                className={this.state.active === 'phones' ? 'menu-item-mobile-active' : 'menu-item-mobile'}
                                disabled = {this.state.active === 'phones'} onClick={this.handleClick('phones')}>
                                <Icon name='phone' style={{marginRight: '24px'}} />
                                <span>Phone Numbers</span>
                            </div>

                            <div onClick={this.logout} style={{cursor: 'pointer'}}>
                                <div
                                    className='menu-item-mobile'
                                    style={{  display: 'flex', justifyContent: 'center', color: '#010101', fontSize: '17px', height: '65px', lineHeight: '65px', fontWeight: '300', cursor: 'pointer' }}>
                                    <Icon name='power off' style={{ marginRight: '24px'}} />
                                    <span>Log Out</span>
                                </div>
                                {/*<span style={{position: 'absolute', color: '#343434', fontSize: '16px', marginLeft: '42px', marginTop: '-8px', lineHeight: '1', fontWeight: '300', opacity: '0.8', overflowWrap: 'anywhere'}}>*/}
                        {/*{Auth.userProfile.username}*/}
                        {/*</span>*/}
                            </div>

                        </div>
                    )}



                </div>

            </div>

        );
    }
}
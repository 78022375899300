import SanitizedHTML from "react-sanitized-html";
import sanitizeHtml from "sanitize-html";
import React from "react";
import './MailBody.css'
import {Button} from "semantic-ui-react";


const ALLOWED_TAGS = ['h3', 'h4', 'h5', 'h6', 'blockquote', 'p', 'a', 'ul', 'ol', 'nl', 'li', 'b', 'i', 'strong',
    'em', 'strike', 'code', 'hr', 'br', 'div', 'table', 'thead', 'caption', 'tbody', 'tr', 'th',
    'td', 'pre', 'h2', 'span'];
const ALLOWED_TAGS_DANGEROUS = ALLOWED_TAGS.concat(['img']);
const ALLOWED_ATTRIBUTES = { 'a': ['href', 'name', 'target'] };
const ALLOWED_ATTRIBUTES_DANGEROUS = { 'a': ['href', 'name', 'target'], 'img': ["src"], '*': ["style"] };

export default class MailBody extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            dangerousMail: false
        }
    }

    render() {

        const showTextOnly = this.props.mail && !this.props.mail.html && this.props.mail.text;

        return (

            <div>

                { !this.state.dangerousMail && !showTextOnly && (
                    <div className='dangerous-info'>
                        <div className='dangerous-info-text'>
                            Images and other elements can contain tracking tags. We've stripped them off.
                        </div>
                        <Button className='dangerous-info-button' size='tiny' style={{marginLeft: '24px'}}
                                onClick={() => {console.log("Clicked"); this.setState({dangerousMail: true})}} >
                            Display Original
                        </Button>
                    </div>
                )}

                <div className='mail-body-wrapper'>

                    {this.props.mail.html && this.state.dangerousMail && (
                        <SanitizedHTML
                            allowedTags={ALLOWED_TAGS_DANGEROUS}
                            allowedAttributes={ALLOWED_ATTRIBUTES_DANGEROUS}
                            transformTags={{
                                'a': sanitizeHtml.simpleTransform('a', {target: '_blank'})
                            }}
                            html={this.props.mail.html}
                        />
                    )}

                    {this.props.mail.html && !this.state.dangerousMail && (
                        //TODO: ESCAPE DANGEROUS HTML & JAVASCRIPT !!!!!!!!!!
                        <SanitizedHTML
                            allowedTags={ALLOWED_TAGS}
                            allowedAttributes={ALLOWED_ATTRIBUTES}
                            transformTags={{
                                'a': sanitizeHtml.simpleTransform('a', {target: '_blank'})
                            }}
                            html={this.props.mail.html}
                        />

                    )}

                    { showTextOnly && (
                        <div className='mail-text-body'>{this.props.mail.text}</div>
                    )}

                </div>

            </div>
        )
    }
};

import React from "react";
import _ from "lodash";
import {Divider, Header, Icon} from "semantic-ui-react";

export class SafetyStatus extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
        }
    }

    render() {

        return (
            <Divider horizontal inverted>

                <Header as='h3' style={{color: '#172E3A', fontWeight: '300', fontSize: '19px'}}>
                    Your Identities
                </Header>

            </Divider>
        )
    }
}
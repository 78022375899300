import React from "react";
import {Flag, Popup} from "semantic-ui-react";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {GeneratePhoneModal} from "./GeneratePhoneModal";

export class PhoneNumberView extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            // phone: this.props.phone
        };
    }

    render() {
        const { phone } = this.state;

        return (
            <div style={{display: 'flex'}}>

                { !phone && (
                    <span style={{color: 'grey', marginLeft: '16px', marginTop: 'auto', fontSize: '14px', fontWeight: '300', marginBottom: 'auto', display: 'inline-block'}}>Not Assigned</span>
                )}

                { phone && (
                    <Popup trigger={
                        <CopyToClipboard text={phone.number} onCopy={this.props.onCopy}  >
                            <div>
                                <span style={{margin: '0 12px', cursor: 'pointer', display: 'inline-block'}}>{phone.number}</span>
                                <Flag name={phone.country}/>
                            </div>
                        </CopyToClipboard>
                    }  position='top center' content='Click to Copy'/>
                )}

                {/*{!phone && (*/}
                    {/*<GeneratePhoneModal onNumberGenerated={(phone) => this.setState({phone})}/>*/}
                {/*)}*/}

            </div>
        );
    }

}
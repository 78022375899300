import {Grid, Icon, Image, List, Message, Placeholder, Popup} from "semantic-ui-react";
import _ from "lodash";
import React from "react";

import './MessageReader.css'
import './MailboxView.css';
import moment from "moment";
import MailBody from "./MailBody";
import Mailbox from "../utils/Mailbox";
import Vault from "../Vault";
import GlobalContext from "./context/GlobalContext";

export default class MailList extends React.Component {

    static contextType = GlobalContext;

    constructor(props) {
        super(props);

        this.state = {
            selectedMailId: null
        }

        this.handleMailClick = this.handleMailClick.bind(this);
    }

    handleMailClick = (mailId, identityId) => () => {

        if (this.state.selectedMailId === mailId) {
            this.setState({
                selectedMailId: null,
            });
            return;
        }

        this.setState({
            selectedMailLoading: true,
            selectedMailId: mailId,
            showDangerousMail: false,
        });

        Mailbox
            .getMail(mailId, identityId, (mails) => this.context.updateMails(mails))
            .then(mail => {
                if (this.state.selectedMailId === mailId) {
                    this.setState({
                        selectedMail: mail,
                        selectedMailLoading: false,
                        selectedMailLoaded: true,
                        selectedMailError: null,
                    });
                }
            })
            .catch(err => {
                console.error("Mail read/decrypt error", err);
                this.setState({
                    selectedMail : null,
                    selectedMailLoading: false,
                    selectedMailLoaded: true,
                    selectedMailError: "Something has gone wrong. Please try again",
                });
            });

    }

    render() {

        return (
            <div>
                <List>
                    {
                        _.map(this.props.summaries, (summary) => (
                            <List.Item key={summary.mailId} style={{ width: '100%'}}>

                                <List.Content className={`mail-list-entry
                                                          ${this.state.selectedMailId === summary.mailId ? "active" : ""}
                                                          ${summary.isCompromised ? "compromised" : ""}
                                                          `}>

                                    <Grid columns={3} onClick={this.handleMailClick(summary.mailId, summary.identityId)} className='mail-summary'>

                                        <Grid.Row style={{color: "#010101", paddingLeft: '1em', paddingRight: '1em'}}>

                                            <Grid.Column computer={1} tablet={1} wide={1} className='mail-avatar-wrapper'>

                                                <Image
                                                    src={(summary.identityId)
                                                        ?   'https://logo.clearbit.com/' +
                                                        Vault.getIdentityById(summary.identityId).domain +
                                                        '?size=80'
                                                        :   '/images/generic.png'
                                                    }

                                                    onError={i => i.target.src = '/images/generic.png'}
                                                    style={{height: '40px'}}
                                                />

                                                {/*<Image src='/images/generic.png' style={{height: '40px'}}/>*/}

                                            </Grid.Column>

                                            <Grid.Column mobile={16} tablet={13} computer={14} wide={14} style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                flexDirection: 'column',
                                            }}>

                                                <div>

                                                    <div className='mail-min-header'>

                                                        <span className='mail-min-header-sender'>{summary.from.name}</span>

                                                        <svg height="2" width={13}
                                                             style={{margin: "0 16px", opacity: "0.2"}}>
                                                            <line x1={0} y1={0} x2={13} y2={0}
                                                                  style={{stroke: "#404040", strokeWidth: "2"}}/>
                                                        </svg>

                                                        <span style={{maxWidth: "58px"}}>{formatDate(summary.date)}</span>

                                                        {/*<Image className='mail-avatar-small' src='/images/generic.png'/>*/}

                                                        <Image
                                                            src={(summary.identityId)
                                                                ?   'https://logo.clearbit.com/' +
                                                                Vault.getIdentityById(summary.identityId).domain +
                                                                '?size=80'
                                                                :   '/images/generic.png'
                                                            }
                                                            onError={i => i.target.src = '/images/generic.png'}
                                                            className='mail-avatar-small'
                                                        />

                                                        {summary.isCompromised && (
                                                            <Popup
                                                                trigger={

                                                                    <Icon name='warning circle' size='small'
                                                                          className='mail-safety-status-icon-small'
                                                                          style={{color: '#F76262'}}/>
                                                                }
                                                                position='top left'
                                                            >
                                                                <Popup.Header>This ID is likely compromised</Popup.Header>
                                                                <Popup.Content>
                                                                    Identity is registered for<br/> <strong style={{fontSize: '16px'}}>{Vault.getIdentityById(summary.identityId).domain}</strong>
                                                                    <br/>
                                                                    but this message came from<br/> <strong style={{color: '#F76262', fontSize: '16px'}}>{summary.from.address.split('@')[1]}</strong>
                                                                </Popup.Content>
                                                            </Popup>


                                                        )}
                                                        {!summary.isCompromised && (
                                                            <Icon name='shield alternate' size='large'
                                                                  className='mail-safety-status-icon-small'
                                                                  style={{color: '#21BA45'}}/>
                                                        )}

                                                    </div>

                                                    {this.state.selectedMailId === summary.mailId && (
                                                        <div>
                                                            <span className='mail-min-header-sender-email'>{summary.from.address}</span>
                                                        </div>
                                                    )}


                                                </div>

                                                <div className={`mail-subject ${summary.alreadyRead ? "" : "mail-unread"}`}>
                                                    {summary.subject}
                                                </div>

                                                {/*<div className='mail-preview'>Preview text, the first line of the mail*/}
                                                {/*...*/}
                                                {/*</div>*/}

                                            </Grid.Column>

                                            <Grid.Column mobile={2} tablet={2} computer={1} wide={1}
                                                // style={{backgroundColor: 'grey'}}
                                                         className='mail-status-column'>

                                                <div className='mail-safety-status-wrapper'>

                                                    <Icon name='close' className='mail-close-button'
                                                          onClick={() => {this.setState({selectedMailId: null});}}/>

                                                    {summary.isCompromised && (

                                                        <Popup
                                                            trigger={
                                                                <Icon name='warning circle' size='big'
                                                                      className='mail-safety-status-icon'
                                                                      style={{color: '#F76262'}} />
                                                            }>

                                                            {/*<Popup.Header style={{color: '#F76262', fontSize: '16px'}}>ID is likely compromised</Popup.Header>*/}
                                                            <Popup.Content style={{fontWeight: '300', fontFamily: '"Work Sans", sans-serif'}}>
                                                                Identity is registered for<br/> <strong style={{fontSize: '16px'}}>{Vault.getIdentityById(summary.identityId).domain}</strong>
                                                                <br/>
                                                                but this message came from<br/> <strong style={{color: '#F76262', fontSize: '16px'}}>{summary.from.address.split('@')[1]}</strong>
                                                            </Popup.Content>
                                                        </Popup>
                                                    )}
                                                    {!summary.isCompromised && (
                                                        <Icon name='shield alternate' size='big'
                                                              className='mail-safety-status-icon'
                                                              style={{color: '#21BA45'}}/>
                                                    )}

                                                </div>


                                            </Grid.Column>

                                        </Grid.Row>

                                    </Grid>

                                    {this.state.selectedMailId === summary.mailId && (
                                        <div className='mail-list-entry-content'>

                                            {this.state.selectedMailLoading && (

                                                <Placeholder fluid style={{width: '90%'}}>
                                                    <Placeholder.Paragraph>
                                                        <Placeholder.Line />
                                                        <Placeholder.Line />
                                                        <Placeholder.Line />
                                                        <Placeholder.Line />
                                                        <Placeholder.Line />
                                                    </Placeholder.Paragraph>
                                                    <Placeholder.Paragraph>
                                                        <Placeholder.Line />
                                                        <Placeholder.Line />
                                                        <Placeholder.Line />
                                                    </Placeholder.Paragraph>
                                                </Placeholder>

                                            )}

                                            {!this.state.selectedMailError && !this.state.selectedMailLoading && this.state.selectedMailLoaded && (

                                                <MailBody mail={this.state.selectedMail}
                                                          dangerousMail={this.state.showDangerousMail}

                                                />
                                            )}

                                            {this.state.selectedMailError && !this.state.selectedMailLoading && (
                                                <Message negative>
                                                    <Message.Header>{this.state.selectedMailError}</Message.Header>
                                                </Message>
                                            )}

                                            {!this.state.selectedMailLoading && this.state.selectedMailLoaded && (

                                                <div className='mail-body-close-btn' onClick={this.handleMailClick(summary.mailId, summary.identityId)}>
                                                    <Icon style={{color: "#172E3A82"}} name='close'/>Close Message
                                                </div>

                                            )}

                                        </div>
                                    )}

                                </List.Content>

                            </List.Item>
                        ))
                    }
                </List>

                {this.props.summaries && this.props.summaries.length >= 10 && (
                    <div className='mail-list-limit'>
                        (Alpha) Only the last 10 messages for each identity
                    </div>
                )}

            </div>
        );
    }

}

function formatDate(dateStr) {
    if (!moment(new Date()).isSame(dateStr, "day")) {
        return moment(dateStr).format("DD MMM");
    } else {
        return moment(dateStr).format("LT");
    }

}
import React from "react";
import {Button, Modal, Icon} from "semantic-ui-react";
import './PhoneDashboard.css'
import {isMobile} from "react-device-detect";
import GlobalContext from "./context/GlobalContext";
import IncomingCallManager from "../utils/IncomingCallManager";

export class PickUpCallModal extends React.Component {

    static contextType = GlobalContext;

    constructor(props) {
        super(props);

        this.state = {
            open: false,
            status: null,
            error: null,
            isDeviceReady: false,
            isCallInProgress: false,
            isCallHungUp: false,
        }

        this.onHangUpClick = this.onHangUpClick.bind(this);
        this.onPickUpClick = this.onPickUpClick.bind(this);
        this.onRejectClick = this.onRejectClick.bind(this);

        this.updateStatus = this.updateStatus.bind(this);
    }

    componentDidMount() {

        this.incomingCallSubscription = IncomingCallManager.getObservable().subscribe(
            call => {

                switch(call.type) {

                    case 'newCall' : {
                        this.setState({open: true});
                        break;
                    }

                    case 'cancel' : {
                        this.onHangUpClick();
                        break;
                    }

                    case 'disconnect' : {
                        this.onHangUpClick(false);
                        this.updateStatus("The person hung up");
                        setTimeout(() => this.close(), 5000);
                        break;
                    }

                }

            },
            error => {
                console.error("PickUpModal | Error occurred : " + error);
                this.onHangUpClick(false);
                this.updateStatus(null, "Network or Microphone issue. Check settings.");
                setTimeout(() => this.close(), 5000);
            }
        )
    }

    componentWillUnmount() {
        if (this.incomingCallSubscription) {
            this.incomingCallSubscription.unsubscribe();
        }
    }

    close = () => {
        console.log("Close called");
        this.setState({
            open: false,
            fromPhoneNumber: null,
            status: null,
            error: null,
            isCallInProgress: false,
            isCallCostComputed: false,
            isCallHungUp: false
        });
    }

    updateStatus(status, error) {
        this.setState({ status, error })
    }

    onPickUpClick() {
        IncomingCallManager.accept();
        this.updateStatus('In progress...');
        this.setState({ isCallInProgress: true });
    }

    onRejectClick() {
        IncomingCallManager.reject();
        this.close();
    }

    onHangUpClick(close=true) {
        this.setState({isCallHungUp: true});
        IncomingCallManager.hangUp();
        if (close) {
            this.close();
        }
    }


    render() {

        const activeCall = IncomingCallManager.getActiveCall()
        const fromPhoneNumber = activeCall && activeCall.parameters && activeCall.parameters.From ? activeCall.parameters.From : '';

        return (
            <Modal size={isMobile ? 'tiny' : 'mini'}
                   open={this.state.open}
                   closeOnEscape={false}
                   closeOnDimmerClick={false}
                   onClose={this.close}
            >

                <Modal.Content className='make-call-modal-content'>

                    {fromPhoneNumber && (
                        <React.Fragment>
                            <div className='make-call-your-number-header'>
                                Incoming call from
                            </div>

                            <div className='make-call-dest-number-header' style={{fontSize: '31px', marginTop: '4px', color: 'black'}}>
                                {fromPhoneNumber}
                            </div>
                        </React.Fragment>
                    )}

                    {this.state.status && (
                        <div style={{width: '100%', textAlign: 'center', marginBottom: '16px', marginTop: '16px', opacity: '0.5'}}>
                            <span style={{fontWeight: '300'}}>Status:</span> {this.state.status}
                        </div>
                    )}

                    {this.state.error && (
                        <div style={{width: '100%', textAlign: 'center', fontSize: '13px', marginBottom: '8px', marginTop: '16px'}}>
                            <Icon name='warning sign' color='red'/>
                            <span style={{fontWeight: '300', color: 'red'}}>{this.state.error}</span>
                        </div>
                    )}


                    {!this.state.isCallHungUp && (
                        <div style={{display: 'flex'}}>

                            { !this.state.isCallInProgress && (
                                <React.Fragment>
                                    <Button size='big' className='btn-hangup' onClick={this.onRejectClick}
                                            style={{width: '100%', fontSize: '16px', borderRadius: '2px', margin: '24px 12px 24px 0' }}>
                                        Reject
                                    </Button>

                                    <Button size='big' color='green' onClick={this.onPickUpClick}
                                            style={{width: '100%', fontSize: '16px', borderRadius: '2px', margin: '24px 0px 24px 12px'  }}>
                                        Pick Up
                                    </Button>
                                </React.Fragment>
                            )}

                            { this.state.isCallInProgress && (
                                <Button size='big' className='btn-hangup' onClick={this.onHangUpClick}
                                        style={{width: '100%', margin: '16px 0px 24px 0px'}}>
                                    Hang Up
                                </Button>
                            )}

                        </div>
                    )}

                    <div style={{backgroundColor: '#f5fbfb', borderRadius: '2px', margin: '0 -52px -32px -52px', padding: '16px', fontWeight: '300', fontSize: '16px', color: "#111111", textAlign: 'center'}}>
                        Maximum call duration is <strong>3min</strong>.<br/>
                        <span style={{fontSize: '14px'}}>It's beta version.</span>
                    </div>

                </Modal.Content>
            </Modal>
        );
    }

}
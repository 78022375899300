import React from "react";
import './InfoMessage.css';
import {Icon} from "semantic-ui-react";


export class InfoMessage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isVisible: false,
            message: null
        };

        this.show = this.show.bind(this);
    }

    show(message) {
        this.setState({
            message,
            isVisible: true
        });

        setTimeout(() => {this.setState({isVisible: false})}, 1000);
    }

render() {
        return (
            <div className='info-msg' style={{height: '48px', zIndex: (this.state.isVisible ? 99 : -1)}}>
                {this.state.isVisible && (
                    <div
                        className='info-msg-content'
                        style={{width: (this.props.width ? this.props.width : '100%'),
                                height: (this.props.height ? this.props.height : '48px'),
                            position: 'absolute', top: '20%', right: '40%'}}>
                        <Icon name='info circle' size='large' className='info-msg-icon'/>
                        <strong>{this.state.message}</strong>
                    </div>
                )}
            </div>
        );
    }
}

/*global argon2*/

import Backend from "./Backend";
import Auth from "./Auth";
import _ from 'lodash';
import moment from 'moment';
import CryptoUtils from "./utils/CryptoUtils";

const passwordGenerator = require('generate-password');

const TYPE_CONFIG = 'c';
const TYPE_ID = 'i';
const TYPE_PHONE = 'p';

var openpgp = require('openpgp');
openpgp.initWorker({ path:'/lib/openpgp.worker.js' }) //   set the relative web worker path

export default class Vault {

    static identities = {};
    static phones = {};
    static encryptionKeySalt;
    static encryptionKey;
    static vaultId;
    static vaultLastModified;
    static ownerIdentityId;
    static recognitionId;

    static config;

    static vault;

    static async setupFirstTime(masterPassword, vaultId, ownerIdentityId) {

        this.vaultId = vaultId;
        this.ownerIdentityId = ownerIdentityId;

        Vault.encryptionKeySalt = this.generateRandomSalt();
        this.encryptionKey = await this.generateKeyFromPassword(masterPassword, Vault.encryptionKeySalt);

        const vault = { ownerIdentityId: this.ownerIdentityId };
        return this.encryptAndSave(vault, TYPE_CONFIG);

    }

    static async updateRecognitionVault(recognitionId) {
        const vault = { ownerIdentityId: this.ownerIdentityId, recognitionId: recognitionId};
        return this.encryptAndSave(vault, TYPE_CONFIG);
    }

    static async initIdentities(vaultId, masterPassword, onLoad) {

        this.loadVault(vaultId, masterPassword)
            .then(() => {
                onLoad(this.identities);
            })
            .catch(err => {
                console.log("Problem: " + err);
                onLoad(null);
            });

        // Check Identities update every 1min
        setInterval(() => { if (Auth.isLoggedIn) this.checkForNewVaultEntries(); }, 15000);

    }

    static async loadVault(vaultId, masterPassword) {

        const loadingDate = moment();

        this.vaultId = vaultId;

        const vaultEntries = await this.getEntireVaultFromServer();
        if (!vaultEntries) {
            console.error("[!] Problem getting data from server");
            throw "Problem getting Vault data"
        }

        await this.findAndProcessConfig(vaultEntries, masterPassword);

        for(let i=0;i < vaultEntries.length; i++) {

            let vaultEntry = vaultEntries[i];
            const vault = await this.decryptVaultEntry(vaultEntry, this.encryptionKey);

            if (vault.type == TYPE_CONFIG) {
                this.ownerIdentityId = vault.data.ownerIdentityId;
                this.recognitionId = vault.data.recognitionId;
            }

            if (vault.type == TYPE_ID) {

                const domain = vault.data.domain;
                if (!this.identities[domain]) {
                    this.identities[domain] = [];
                }
                this.identities[domain].push(vault.data);

            }

            if (vault.type == TYPE_PHONE) {
                this.phones[vault.data.id] = vault.data;
            }

        }

        this.vaultLastModified = loadingDate;
    }

    static async checkForNewVaultEntries() {

        const checkDate = moment();

        const vaultEntries = await this.getEntireVaultFromServer(this.vaultLastModified);
        if (!vaultEntries) {
            console.error("[!] Problem getting data from server");
            throw "Problem getting Vault data"
        }

        for(let i=0;i < vaultEntries.length; i++) {

            let vaultEntry = vaultEntries[i];
            const vault = await this.decryptVaultEntry(vaultEntry, this.encryptionKey);

            if (vault.type == TYPE_ID) {

                const domain = vault.data.domain;
                if (!this.identities[domain]) {
                    this.identities[domain] = [];
                }

                if (this.identities[domain].filter(id => id.email === vault.data.email).length == 0) {
                    this.identities[domain].push(vault.data);
                }

            }

            if (vault.type == TYPE_PHONE) {
                this.phones[vault.data.id] = vault.data;
            }

        }

        this.vaultLastModified = checkDate;
    }

    static countIdentitiesFor(domain) {
        if (this.identities[domain]) {
            return this.identities[domain].length
        }
        return 0;
    }


    static async findAndProcessConfig(vaultEntries, masterPassword) {

        let configEntries = vaultEntries.filter(entry => entry.type == TYPE_CONFIG);
        if (!configEntries || configEntries.length != 1) {
            console.error("[!] Problem getting data from server : no config entry");
            throw "Problem getting Vault data";
        }

        let passwordKeySaltHex = configEntries[0].data.substr(0, 32);
        Vault.encryptionKeySalt = this.fromHexStringToUint8Array(passwordKeySaltHex);
        Vault.encryptionKey = await this.generateKeyFromPassword(masterPassword, Vault.encryptionKeySalt);
    }

    // Identity Creation

    static generateSecurePassword(len=16) {
        return passwordGenerator.generate({
            length: len,
            numbers: true,
            symbols: true,
            strict : true
        })
    }

    static async generateNewIdentityFor(domain, note) {

        const keys = await this.generateRsaKeys();

        const newIdentityMeta = {
            //TODO: move session token to Headers
            sessionToken : Auth.sessionToken,
            domain,
            pub_key: keys.public,
            ownerIdentityId: this.ownerIdentityId
        };

        //TODO: it'd be good to move this piece outside

        let registeredId = await Backend.registerNewIdentity(newIdentityMeta)
            .then((response) => {

                if (!response || !response.status) {
                    console.error("Error when creating new identity");
                    return null;
                }
                //TODO: add error checking(!)
                return response;
            })
            .catch(err => {
                console.log("Error when creating ID: " + err);
                return null;
            })

        if (registeredId == null) {
            return Promise.reject(null);
        }

        const id = {
            keys,
            email : registeredId.email,
            id : registeredId.id,
            password : this.generateSecurePassword(),
            domain : domain,
            note : note,
            date : moment()
        };

        if (!Vault.identities[domain]) {
            Vault.identities[domain] = [];
        }
        Vault.identities[domain].push(id);

        await this.encryptAndSave(id, TYPE_ID);
        //TODO: add error handling

        return Promise.resolve(id);
    };

    static async generateRsaKeys() {

        // var my_user_id = "John Test <john_test@someserver.com>";

        //TODO: implement ECC
        // const keyOptions = {
        //     userId: my_user_id,
        //     numBits: 2048,
        //     passphrase: keyPasswd
        // };

        const keyPassword = this.generateSecurePassword(25);
        const keyOptions = {
            // userIds: [{ email }],           // multiple user IDs
            userIds: [{ }],           // multiple user IDs
            curve: "ed25519",               // ECC curve name
            passphrase: keyPassword         // protects the private key
        };

        const keys = await openpgp.generateKey(keyOptions)
            .then((key) => {
                return {
                    private : key.privateKeyArmored,
                    public : key.publicKeyArmored,
                    keyPassword
                }
            })
            .catch((e) => {
                console.log("Problem when generating RSA keys : " + JSON.stringify(e));
                throw e;
            });

        return keys;
    }

    static async encryptAndSave(data, type) {
        const meta = CryptoUtils.encryptAes256GCM(JSON.stringify(data), this.encryptionKey);

        let vaultEntry = meta.iv + meta.tag + meta.encrypted;
        if (type === TYPE_CONFIG) {
            vaultEntry = Buffer.from(this.encryptionKeySalt).toString('hex') + vaultEntry;
        }

        return await this.saveVaultToServer(vaultEntry, type);
    }

    static async saveVaultToServer(encryptedVault, type) {

        if (type == TYPE_CONFIG) {

            const body = {
                sessionToken: Auth.sessionToken,
                id: this.vaultId,
                type,
                data: encryptedVault,
            }


            return Backend.updateVault(body)
                .then((response) => {
                    if (!response || !response.status) {
                        throw Error("Problem when saving vault");
                    }
                    return response;
                })
                .catch(err => {
                    //TODO: add error handling
                });
        }

        const body = {
            sessionToken: Auth.sessionToken,
            vaultId: this.vaultId,
            type: type,
            data: encryptedVault,
        }


        return Backend.saveVault(body)
            .then((response) => {
                if (!response || !response.status) {
                    throw Error("Problem when saving vault");
                }
                return response.data;
            })
            .catch(err => {
                //TODO: add error handling
            });
    }

    static getIdentityById(requestedId) {

        if (!this.identities || !requestedId) {
            return null;
        }

        let foundId = null;
        _.each(this.identities, (ids, domain) => {

            _.each(ids, (id) => {
                if (id.id === requestedId) {
                    foundId = id;
                    return false;
                }
            });

            return (!foundId);
        });

        return foundId;
    }

    //TODO: implement it with map
    static getPhoneById(phoneId) {
        if (!this.phones || !phoneId) {
            return null;
        }

        // let foundPhone = null;

        return this.phones[phoneId];

        // //TODO: it's map so use get()
        // _.each(this.phones.values(), (phone) => {
        //     if (phone.id === phoneId) {
        //         foundPhone = phone;
        //         return false;
        //     }
        // });
        //
        // return foundPhone;
    }

    static getAllIdentityIds() {
        let ids = [];
        for(let domain in Vault.identities) {
            Vault.identities[domain].forEach(id => {
                if (id.id) {
                    ids.push(id.id);
                }
            });
        }
        return ids;
    }

    static getAllPhonesIds() {
        // let ids = [];
        return Object.keys(Vault.phones);
        // Vault.phones.values().forEach(phone => {
        //     if (phone.id) {
        //         ids.push(phone.id);
        //     }
        // });
        // return ids;
    }

    static getAllIdentity() {
        let ids = [];
        for(let domain in Vault.identities) {
            Vault.identities[domain].forEach(id => {
                ids.push(id);
            });
        }
        return ids;
    }

    static async addPhoneNumber(phone) {
        if (!phone) {
            return;
        }

        Vault.phones[phone.id] = phone;

        return this.encryptAndSave(phone, TYPE_PHONE)
            .then(() => {
                return true;
            }).catch(err => {
                console.log("Error saving new phone number: " + err);
                return false;
            })
    }

    static async decryptVaultEntry(vaultEntry, key) {

        if (!vaultEntry || !vaultEntry.data) {
            return null;
        }

        let offset = (vaultEntry.type === TYPE_CONFIG ? 32 : 0);

        const encryptedVaultEntry = vaultEntry.data;
        const iv = encryptedVaultEntry.substr(offset, 32);
        const tag = encryptedVaultEntry.substr(offset + 32, 32);
        const encryptedData = encryptedVaultEntry.substr(offset + 64);

        let meta = CryptoUtils.decryptAes256GCM(encryptedData, key, iv, tag);
        if (!meta) {
            console.log("Problem with decrypting VaultEntry (!)");
            return null;
        }

        try {
            const data = JSON.parse(meta.data);
            return {type: vaultEntry.type, data}
        } catch(err) {
            console.log("Error parsing JSON data : " + err);
            return {type: 'error' }
        }
    }

    static async getEntireVaultFromServer(lastModified) {

        const body = {
            sessionToken : Auth.sessionToken,
            vaultId: this.vaultId,
            lastModified: (lastModified ? lastModified : undefined)
        }

        return Backend
            .getVault(body)
            .then( (response) => {
                if (!response || !response.status) {
                    throw Error("Problem when getting vault");
                }
                return response.data;
            })
            .catch(err => {
                console.error("Error getting vault: " + JSON.stringify(err, null, 3));
                //TODO: add errors handling
            });

    }

    static async generateKeyFromPassword(passwd, salt) {
        let key = await argon2.hash({
            pass: passwd,
            salt: salt,
            hashLen: 32,
            distPath: '/dist'
        })
            // .then(h  => h.hashHex)
            .then(h  => h.hash)
            .catch(e => {
                console.log("!!!!!!!!!!! Problem with generating key : " + JSON.stringify(e));
                return null;
            } );

        return key
    }

    static generateRandomSalt() {
        let salt = new Uint8Array(16);
        window.crypto.getRandomValues(salt);
        return salt;
    }

    static async pgpDecryptMail(encrypted, publicKey, privateKey, keyPassword) {

        const privKeyObj = (await openpgp.key.readArmored(privateKey)).keys[0]
        await privKeyObj.decrypt(keyPassword)

        const options = {
            message: await openpgp.message.readArmored(encrypted),       // parse armored message
            publicKeys: (await openpgp.key.readArmored(publicKey)).keys, // for verification (optional)
            privateKeys: [privKeyObj]                                    // for decryption
        }

        return openpgp.decrypt(options).then(plaintext => {
            return plaintext.data
        })
    }

    static async pgpDecrypt(encrypted, publicKey, privateKey, keyPassword) {

        const privKeyObj = (await openpgp.key.readArmored(privateKey)).keys[0]
        await privKeyObj.decrypt(keyPassword);

        const options = {
            message: await openpgp.message.readArmored(encrypted),       // parse armored message
            publicKeys: (await openpgp.key.readArmored(publicKey)).keys, // for verification (optional)
            privateKeys: [privKeyObj]                                    // for decryption
        }

        return openpgp.decrypt(options).then(plaintext => {
            return plaintext.data
        })
    }

    static fromHexStringToUint8Array(hexString) {
        return new Uint8Array(hexString.match(/.{1,2}/g).map(byte => parseInt(byte, 16)));
    }

    // static async cryptoTest() {
    //
    //     const key = await this.generateKeyFromPassword('aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa', 'saltasfdasfasfdsadf');
    //     const obj = { 'nazwa' : "języki" };
    //
    //     let result = CryptoUtils.encryptAes256GCM(JSON.stringify(obj), key);
    //
    //     let decoded = CryptoUtils.decryptAes256GCM(result.encrypted, key, result.iv, result.tag);
    //     console.log("Decoded: " + JSON.stringify(decoded, null, 3));
    //
    // }
}

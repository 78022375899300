const local = {
    env: 'local',
    API_URL : "http://noid.local/api"
};

const dev = {
    env: 'dev',
    API_URL : "https://dev.api.noid.today/api"
};

const test = {
    env: 'test',
    API_URL : "https://test.api.noid.today/api"
}

const prod = {
    env: 'prod',
    API_URL : "https://alpha.api.noid.today/api"
};

function getConfig() {
    const stage = !process.env.REACT_APP_STAGE ? '' : process.env.REACT_APP_STAGE.trim();
    switch(stage) {
        case 'dev' : return dev;
        case 'test' : return test;
        case 'production' : return prod;
        case 'local' : return local;
        default: return local;
    }
}

const config = getConfig();

export default {
    ...config
};

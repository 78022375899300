import React from "react";
import 'semantic-ui-css/semantic.css';
import './IdentityList.css';
import {Container, Divider, Grid, Icon, Image, Popup} from "semantic-ui-react";
import _ from 'lodash';
import {CopyToClipboard} from "react-copy-to-clipboard";
import {Mixpanel} from "../utils/Mixpanel";
import {PhoneNumberView} from "./PhoneNumberView";
import {InfoMessage} from "./InfoMessage";

export class IdentityList extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            identities: this.props.identities,
            // identitiesArr: this.mapToArray(this.props.identities)
        };

        // this.infoBoxRef = React.createRef();
        this.onValueCopy = this.onValueCopy.bind(this);

    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(this.props.identities, prevProps.identities)) {
            // console.log("Updating identities ARRAY");
            this.setState({
                // identities: this.props.identities,
                identities: this.props.identities
            });
        }
    }

    onValueCopy = (name, text) => () => {
        this.refs.infoBox.show(text);
        Mixpanel.track(name + " Copy clicked");
    }

    render() {

        return (

            <div className='ids-list-wrapper'>

                <Grid stackable doubling>
                    {
                        _.map(this.state.identities, (id) => {
                                if (!id.email) {
                                    return;
                                }
                                return (
                                    <Grid.Column className='identities-list-item-wrapper'
                                                 mobile={16} tablet={16} computer={8} largeScreen={5} widescreen={4}>

                                        <div compact
                                             className={id.isCompromised ? 'identities-list-item-compromised' : 'identities-list-item'}>

                                            {id.isCompromised && (
                                                <div className='compromised-label'>
                                                    LIKELY COMPROMISED
                                                </div>
                                            )}

                                            <Container style={{display: 'flex'}}>

                                                <Popup trigger={
                                                    <div style={{height: '64px'}}>
                                                        <Image src={'https://logo.clearbit.com/' + id.domain + '?size=80'}
                                                               onError={i => i.target.src = '/images/generic.png'}
                                                               style={{maxWidth: '58px'}}/>

                                                        <div style={{position: 'absolute'}}>
                                                            {!id.isCompromised && (
                                                                <Icon style={{
                                                                    position: 'absolute',
                                                                    marginTop: '-16px',
                                                                    marginLeft: '42px'
                                                                }} name='shield alternate' color='green' size='big'/>
                                                            )}
                                                            {id.isCompromised && (
                                                                <Icon style={{
                                                                    position: 'absolute',
                                                                    marginTop: '-16px',
                                                                    marginLeft: '42px'
                                                                }} name='warning circle' color='red' size='big'/>
                                                            )}
                                                        </div>
                                                    </div>
                                                } position='top center'
                                                >
                                                    <Popup.Header style={{color: (!id.isCompromised ? '#21ba45' : '#f76262')}}>
                                                        {!id.isCompromised ? 'Identity is safe' : 'Identity is likely compromised!'}
                                                    </Popup.Header>
                                                    <Popup.Content style={{color: (!id.isCompromised ? 'grey' : '#010101')}}>
                                                        {!id.isCompromised ? 'No threads detected' : 'You started receiving messages from other sources.'}
                                                    </Popup.Content>
                                                </Popup>

                                                <div className='id-header-label'>

                                                    <a style={{fontSize: '19px', color: '#172E3A', fontWeight: '700', lineHeight: '25px' }}
                                                       href={'https://' + id.domain} target='_blank' rel="noopener noreferrer">{id.domain}</a>

                                                </div>
                                            </Container>

                                            <Container style={{marginTop: '24px', position: 'relative'}}>

                                                <CopyToClipboard text={id.email}
                                                                 onCopy={this.onValueCopy("Email", "Email Copied")}>

                                                    <div style={{cursor: 'pointer'}}>

                                                        <div style={{display: 'flex', }}>
                                                            <Icon className='id-content-icon' name='at'/>
                                                            <span className='id-content-email'>{id.email}</span>
                                                        </div>

                                                        <div className='id-content-email-copy-container'>
                                                            <span>COPY</span>
                                                        </div>
                                                    </div>

                                                </CopyToClipboard>

                                            </Container>

                                            <Container style={{marginTop: '24px',  position: 'relative'}}>

                                                <CopyToClipboard text={id.password}
                                                                 onCopy={this.onValueCopy("Password", "Password Copied!")}>

                                                    <div style={{cursor: 'pointer'}}>
                                                        <div style={{display: 'flex', cursor: 'pointer'}}>
                                                            <Icon className='id-content-icon' name='privacy'/>
                                                            <span className='id-content-password'>*******</span>
                                                        </div>

                                                        <div className='id-content-password-copy-container'>
                                                            <span>COPY</span>
                                                        </div>

                                                    </div>
                                                </CopyToClipboard>

                                            </Container>

                                            <Container style={{
                                                display: 'flex',
                                                marginTop: '24px',
                                                fontSize: '14px'
                                            }}>
                                                <Icon className='id-content-icon' name='phone'/>

                                                <PhoneNumberView
                                                    phone={id.phone}
                                                    onCopy={()=> this.onValueCopy("Phone Number", "Phone Number Copied!")}/>
                                            </Container>

                                            <Divider style={{color: '#172E3A', opacity: '0.45' }}/>

                                            <Container style={{marginTop: '12px'}}>
                                                <Popup trigger={
                                                    <span style={{
                                                        display: "inline-block",
                                                        maxWidth: "100%",
                                                        fontSize: '16px',
                                                        whiteSpace: "nowrap",
                                                        overflow: "hidden",
                                                        fontWeight: '400',
                                                        color: (!id.note ? '#a6a6a6' : '#172E3A'),
                                                        textOverflow: "ellipsis"
                                                    }}>
                                                {!id.note ? 'No Comment' : id.note}
                                            </span>
                                                } position='top center' content={id.note}/>
                                            </Container>
                                        </div>
                                    </Grid.Column>
                                );
                            }
                        )
                    }

                </Grid>

                {!this.state.identities || this.state.identities.length == 0 && (

                    <div style={{color: 'grey', fontSize: '35px',  marginTop: '24px', fontWeight: '300', width: '100%', textAlign: 'center'}}>
                        <div style={{opacity: '0.35',}}>Empty</div>
                        <Icon name='users' size='huge' style={{marginTop: '8px', opacity: '0.35',}}/>
                        {/*Empty List*/}
                        <div style={{fontSize: '16px', marginTop: '24px', fontWeight: '300', opacity: '0.75',}}>
                            Create at least one ID to start protecting your privacy.
                            {/*Click the button above and start protecting your privacy.*/}
                        </div>
                    </div>

                )}

                <InfoMessage ref="infoBox"/>

            </div>
        )
    };

}


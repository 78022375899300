import React from 'react';
import Auth from '../Auth';
import Vault from '../Vault';
import Backend from '../Backend';
import { Message, Icon } from 'semantic-ui-react'
import {browserHistory} from 'react-router';

export class RecognitionDashboard extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            result: undefined
        };

        this.handleVerificationError = this.handleVerificationError.bind(this);
        this.handleVerificationSuccess = this.handleVerificationSuccess.bind(this);
    }

    componentDidMount() {
        this.verifyRecognition();
    }

    verifyRecognition() {
        const search = window.location.search;
        const params = new URLSearchParams(search);

        const code = params.get('code');
        const state = params.get('state');

        if (!code || !state) {
            this.props.history.push('/main');
            return;
        }

        const payload = {
            sessionToken: Auth.sessionToken,
            ownerIdentityId: Vault.ownerIdentityId,
            code: code,
            state: state
        };

        Backend
            .verifyRecognition(payload)
            .then(response => {
                if (!response || !response.status) {
                    return this.handleVerificationError();
                }

                const recognitionId = response.data._id;
                Vault.updateRecognitionVault(recognitionId)
                    .then(response => {
                        if (!response || !response.status) {
                            return this.handleVerificationError();
                        }
                        Vault.recognitionId = recognitionId;
                        return this.handleVerificationSuccess();
                    })
            })
            .catch(err => {
                console.log("Error: " + err);
                return this.handleVerificationError();
            });
    }

    handleVerificationError() {
        this.setState({loading: false, result: false});
        setTimeout(function(){
            this.props.history.push('/main');
        }.bind(this), 3000);
    }

    handleVerificationSuccess() {
        this.setState({loading: false, result: true});
        setTimeout(function(){
            this.props.history.push('/main');
        }.bind(this), 3000);
    }

    render() {
        return (
            <div className='dashboard-wrapper'>
            <Message icon>
                <Icon name='circle notched' loading />
                {this.state.loading &&
                    <Message.Content>
                        <Message.Header>Just one second</Message.Header>
                        We are fetching data for you.
                    </Message.Content>
                }
                {!this.state.loading && this.state.result &&
                    <Message.Content>
                        <Message.Header>Recognition passed</Message.Header>
                        You will be redirected to main page in a moment.
                    </Message.Content>
                }
                {!this.state.loading && this.state.result === false &&
                    <Message.Content>
                        <Message.Header>Recognition failed</Message.Header>
                        An error occurred while recognition.
                    </Message.Content>
                }
            </Message>
            </div>
        )
    }
}

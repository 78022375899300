import React from "react";
import _ from "lodash";
import {Button, Flag, Grid, Icon, List, Loader} from "semantic-ui-react";
import './PhoneList.css';
import {MakeCallModal} from "./MakeCallModal";
import SmsBox from "../utils/SmsBox";
import moment from "moment";
import PhoneNumberManager from "../utils/PhoneNumberManager";
import GlobalContext from "./context/GlobalContext";

export class PhoneList extends React.Component {

    static contextType = GlobalContext;

    constructor(props) {
        super(props);

        this.state = {
            phones: PhoneNumberManager.getAll(),
            showSmsBoxFor: null,
            failedPhoneIds: [],
            successPhoneIds: null,
            messages: []
        }

        this.toggleSmsBox = this.toggleSmsBox.bind(this);
        this.loadSms = this.loadSms.bind(this);
        this.refreshSms = this.refreshSms.bind(this);
    }

    componentDidMount() {
        if (this.context.smsMessages === undefined) {
            this.loadSms();
        }
    }

    toggleSmsBox = (phoneId) => {
        this.setState((prevState) => {
            return {
                showSmsBoxFor: (!prevState.showSmsBoxFor || prevState.showSmsBoxFor !== phoneId ? phoneId : null)
            }
        });
        this.context.removeNewSmsPhoneId(phoneId);
    };

    loadSms() {

        this.setState({
            isLoading: true
        });

        SmsBox
            .loadAll((failedPhoneIds, successPhoneIds, allSmsSummaryById, newSmsPhoneIds) => {
                this.context.updateSmsMessages(allSmsSummaryById);
                this.setState({ failedPhoneIds, successPhoneIds, allSmsSummaryById })
            })
            .catch(err => {
                console.error("Loading ss ERROR 3: " + err);
                this.setState({
                    isLoading: false,
                    error: 'Problem with loading sms'
                });
            })
    }

    refreshSms() {
        this.setState({
            isLoading: true
        });

        SmsBox
            .refresh(( failedPhoneIds, successPhoneIds, allSmsSummaryById, newSmsPhoneIds) => {
                this.context.updateSmsMessages(allSmsSummaryById);
                this.setState({ failedPhoneIds, successPhoneIds, allSmsSummaryById })
            })
            .catch(err => {
                console.error("Loading ss ERROR 4: " + err);
                this.setState({
                    isLoading: false,
                    error: 'Problem with loading sms'
                });
            })
    }

    addSucceededPhoneId(phoneId) {
        let successPhoneIds = this.state.successPhoneIds || [];
        successPhoneIds.push(phoneId);
        this.setState({successPhoneIds});
    }

    render() {

        const isEmpty = (!this.state.phones);
        const smsMessages = this.context.smsMessages || [];
        const successPhoneIds = this.state.successPhoneIds || PhoneNumberManager.getAllPhonesIds();

        return (
            <div className='phone-list-wrapper'>
                <div className='phone-list'>

                    { isEmpty  && (
                        <div className='phone-list-empty-label'>
                            <span style={{color: '#010101', fontWeight: '300', opacity: '0.5',}}>You Have No Phone Numbers</span>
                        </div>
                    )}

                    { !isEmpty  && (

                        <List verticalAlign='middle'>

                            {_.map(this.state.phones, (phone) => (
                                    <List.Item className='phone-list-item'>

                                        <Grid>

                                            <Grid.Row only='computer' column={5}>

                                                <Grid.Column computer={1} style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center'}}>
                                                    <Flag name={phone.country.toLowerCase()}/>
                                                </Grid.Column>

                                                <Grid.Column computer={5} className='phone-name-column'>
                                                    <span>{phone.friendlyName}</span>
                                                </Grid.Column>

                                                <Grid.Column computer={4} widescreen={3} style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center'
                                                }}>
                                                    <MakeCallModal phone={phone}/>
                                                </Grid.Column>

                                                <Grid.Column computer={4} widescreen={4}
                                                             className='nowe'
                                                             style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center'
                                                }}>
                                                    <Button size='large'
                                                            className={`
                                                                ${this.state.showSmsBoxFor === phone.id ? 'btn-sms-clicked' : 'btn-sms'}
                                                                `}
                                                            onClick={() => {
                                                                this.toggleSmsBox(phone.id);
                                                            }}>
                                                        <Icon name={this.state.showSmsBoxFor === phone.id ? 'close' : 'mail'} color='gray'/>
                                                        <span className={this.context.flags.newSmsPhoneIds.has(phone.id) ? 'has-new-content' : ''}>
                                                            {this.state.showSmsBoxFor === phone.id ? 'Close List' : 'Show SMS'}
                                                        </span>
                                                    </Button>
                                                    {/*<Icon name='circle thin' size='tiny' style={{color: "#3E8A98", opacity: '0.45', marginLeft: '8px'}} />*/}
                                                </Grid.Column>

                                                <Grid.Column computer={2} widescreen={3} className='phone-status' style={{
                                                    display: 'flex',
                                                    justifyContent: 'right !important',
                                                    alignItems: 'center'
                                                }}>
                                                    <Icon name='shield' size='large' style={{color: "#21BA45", opacity: '0.45'}} />
                                                </Grid.Column>

                                            </Grid.Row>

                                            <Grid.Row only='mobile tablet' column={2} style={{paddingLeft: '16px', paddingRight: '16px'}}>

                                                <Grid.Column mobile={2}>
                                                    <Flag name={phone.country.toLowerCase()}/>
                                                </Grid.Column>

                                                <Grid.Column width={12} className='phone-number'>
                                                    <span>{phone.friendlyName}</span>
                                                </Grid.Column>


                                                <Grid.Column width={2}>
                                                    <Icon name='shield' style={{color: "#21BA45", opacity: '0.45'}}/>
                                                </Grid.Column>

                                            </Grid.Row>

                                            <Grid.Row only='mobile tablet' column={2}>

                                                <Grid.Column mobile={16} tablet={8} className='button-column'>
                                                    <MakeCallModal phone={phone}/>
                                                </Grid.Column>

                                                <Grid.Column mobile={16} tablet={8} className='button-column'>
                                                    <Button className={this.state.showSmsBoxFor === phone.id ? 'btn-sms-clicked' :
                                                        this.context.flags.newSmsPhoneIds.has(phone.id) ? 'btn-sms has-new-content' : 'btn-sms'}
                                                            onClick={() => {
                                                                this.toggleSmsBox(phone.id);
                                                            }}>
                                                        <Icon name={this.state.showSmsBoxFor === phone.id ? 'close' : 'mail'} color='gray'/>
                                                        {this.state.showSmsBoxFor === phone.id ? 'Close List' : 'Show SMS'}
                                                    </Button>
                                                    {/*<Icon name='circle thin' size='small' color='grey' style={{position: 'absolute', right: '20px'}}/>*/}
                                                </Grid.Column>

                                            </Grid.Row>

                                            {this.state.showSmsBoxFor === phone.id && (

                                                <Grid.Row>
                                                    <SmsList
                                                        messages={_.orderBy(smsMessages[phone.id], ["date"], ["desc"])}
                                                        succeeded={successPhoneIds.indexOf(phone.id) !== -1}
                                                        failed={this.state.failedPhoneIds.indexOf(phone.id) !== -1}
                                                        refreshSms={this.refreshSms}
                                                    />
                                                </Grid.Row>

                                            )}

                                        </Grid>

                                    </List.Item>
                                )
                            )}

                        </List>
                    )}
                </div>
            </div>
        )
    }
}

const SmsList = (props) => (
    <div style={{width: '100%'}}>

        { props.succeeded && (!props.messages || props.messages.length === 0) && (
            <span className='sms-list-empty-info'>No SMS</span>
        )}

        { props.succeeded && props.messages && props.messages.length > 0 && (

            <List verticalAlign='middle' className='sms-list'>

                {_.map(props.messages, (sms) => (
                        <List.Item className='sms-list-item'>
                            <Grid>
                                <Grid.Row column={4} className='sms-list-item-header'
                                          style={{fontSize: '16px', color: 'grey'}}>

                                    <Grid.Column mobile={10} tablet={9} computer={6}>
                                        <span style={{fontWeight: '300', marginRight: '8x'}}>From: </span>{sms.from}
                                    </Grid.Column>

                                    <Grid.Column mobile={6} tablet={7} computer={9} style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        flexDirection: 'column'
                                    }}>
                                        <span style={{fontWeight: '300', textAlign: 'right'}}>{formatDate(sms.date)}</span>
                                    </Grid.Column>

                                </Grid.Row>

                                <Grid.Row className='sms-body'>
                                    <Grid.Column width={16}>
                                        <div>{sms.body} when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. </div>
                                    </Grid.Column>
                                </Grid.Row>

                            </Grid>
                        </List.Item>
                    )
                )}

            </List>

        )}

        {!props.succeeded && !props.failed && (
            <div>
                <Loader active inline='centered' />
            </div>
        )}

        {!props.succeeded && props.failed && (
            <div>
                <span className='sms-list-info'>Could not load sms. Click refresh button</span>
                <Button icon size='medium' onClick={props.refreshSms} style={{backgroundColor: '#4FBBBC', width: '100%'}}>
                    <Icon style={{color: 'white'}} name='refresh'/>
                </Button>
            </div>

        )}

        {props.messages && props.messages.length >= 3 && (
            <span className='sms-list-info'>Beta version. Only the last 10 messages</span>
        )}


    </div>
)

function formatDate(dateStr) {
    if (!moment(new Date()).isSame(dateStr, "day")) {
        return moment(dateStr).format("DD MMM");
    } else {
        return moment(dateStr).format("LT");
    }
}
import React from "react";
import {Button, Icon, Input, Loader, Modal} from "semantic-ui-react";
import './PhoneDashboard.css'
import {Device} from "twilio-client";
import Backend from "../Backend";
import Auth from "../Auth";
import moment from "moment";
import {isMobile} from "react-device-detect";

const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();

export class MakeCallModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            open: false,
            status: null,
            error: null,
            isDeviceReady: false,
            isCallInProgress: false,
            phoneNumber: null,
            isCallCostComputed: false,
            computeCallCostInProgress: false,
            computeCallCostError: null,
            callCost: null,
            isNumberValid: false,

            connectionStartDate: null,
        }

        this.device = null;
        this.activeConnection = null;

        this.updateStatus = this.updateStatus.bind(this);
        this.onCallClick = this.onCallClick.bind(this);
        this.onHangUpClick = this.onHangUpClick.bind(this);
        this.setupCall = this.setupCall.bind(this);
        this.initTwilioDevice = this.initTwilioDevice.bind(this);
        this.onComputeCostClick = this.onComputeCostClick.bind(this);
        this.getCallCost = this.getCallCost.bind(this);
        this.computeCallStatistics = this.computeCallStatistics.bind(this);

    }

    componentDidMount() {
        this.initTwilioDevice();
    }

    componentWillUnmount() {
        if (this.device) {
            this.device.destroy();
            this.device = null;
        }
    }

    close = () => {
        this.onHangUpClick();
        this.setState({
            open: false,
            phoneNumber: null,
            error: null,
            isCallCostComputed: false,
            computeCallCostInProgress: false,
            computeCallCostError: null,
            callCost: null
        });
        // if (this.device) {
        //     this.device.destroy();
        // }
    }

    open = () => {
        this.setState({ open: true })
    }


    onValueChange = (e, { name, value }) => {
        this.setState({
            [name]: value,
            isNumberValid: this.isNumberValid(value),
            isCallCostComputed: false
        });
    }

    isNumberValid(number) {
        try {
            let numberProto = phoneUtil.parse(number, this.props.phone.country);
            // console.log("numberProto: " + JSON.stringify(numberProto, null, 3));
            // console.log("isValid: " + phoneUtil.isValidNumber(numberProto));
            return phoneUtil.isValidNumber(numberProto);
        } catch (e) {
            return false;
        }
    }

    updateStatus(status, error) {
        this.setState({ status, error })
    }

    getCallCost() {

        const params = {
            sessionToken: Auth.sessionToken,
            originationNumber: this.props.phone.number,
            destinationPhoneNumber: this.state.phoneNumber,
            phoneId: this.props.phone.id
        }

        return Backend.getCallCost(params)
            .then(response => {

                if (!response || !response.status || !response.cost || !response.cost.costInCredit) {
                    console.log("Cannot get phone call cost - failed");
                    throw "Request error";
                }

                return response.cost.costInCredit;
            })
            .catch(err => {
                console.log("Cannot get call's cost: " + err);
                throw "Problem when getting call cost";
            });

    }

    onCallClick() {
        this.updateStatus('Connecting...');

        this.getCallCost()
            .then(callCostInCredit => {

                this.setState({
                    callCost: callCostInCredit
                });

                if (callCostInCredit > Auth.userProfile.credit) {
                    throw "No enough credits";
                }

                return Backend
                    .getTokenForPhoneCall({ sessionToken: Auth.personalisedSessionToken, phoneId: this.props.phone.id })
                    .then(response => {
                        if (!response || !response.status || !response.token) {
                            this.updateStatus(null, "Error occurred. Please try again");
                            console.log("Cannot get phone call token - failed");
                            return;
                        }

                        this.setupCall(response.token);
                    })

            })
            .catch(err => {
                if (err && err === 'No enough credits') {
                    this.updateStatus(null);
                } else {
                    this.updateStatus(null, "Error occurred. Please try again");
                }
                console.log("Error getting phone call's cost:" + err);
                return;
            });

    }

    onComputeCostClick() {

        this.setState({
            isCallCostComputed: false,
            computeCallCostInProgress: true
        });

        this.getCallCost()
            .then(callCostInCredit => {
                this.setState({
                    isCallCostComputed: true,
                    computeCallCostInProgress: false,
                    callCost: callCostInCredit
                });
            })
            .catch(err => {
                console.log("Cannot get call's cost: " + err);
                this.setState({
                    isCallCostComputed: false,
                    computeCallCostInProgress: false,
                    callCost: null,
                    computeCallCostError: "Error, please try again."
                });

                setTimeout(() => {
                    this.setState({ computeCallCostError: null });
                }, 1500)

            });
    }

    onHangUpClick() {
        if (!this.activeConnection) {
            return;
        }
        this.activeConnection.disconnect();

        if (this.device) {
            this.device.disconnectAll();
        }
    }

    setupCall(token) {

        if (this.activeConnection || !this.device) {
            console.log("Cannot setup call now. Wait until the device is ready");
            return;
        }

        this.device.setup(token, {debug: true});

        let params = { "phoneNumber" : this.state.phoneNumber, callToken: token };
        this.device.connect(params);

        //TODO: (!) Change that. It's hack to track call duration (for FE only). Ideal implementation uses server's callbacks
        this.setState({ connectionStartDate: moment() });

    }

    initTwilioDevice() {

        if (this.device) {
            // console.log("[DEV] Twilio device is already initiated");
            return;
        }
        this.device = new Device();

        this.device.on('ready', function (device) {
            this.setState( { isDeviceReady: true });
        }.bind(this));

        this.device.on('connect', function (connection) {

            this.activeConnection = connection;

            // Add connection's handlers
            //
            connection.on('error', (connError) => {
                console.warn("Connection error "); // + JSON.stringify(connError, null, 3));
                if (connError && connError.code == 31201) {
                    this.updateStatus(null, "Microphone issue. Check your device.")
                    return;
                }
                if (connError) {
                    this.updateStatus(null, "Unknown Connection Error. Try again later.")
                }
            });

            connection.on('accept', (connection2) => {
                this.updateStatus("In progress")
            });

            connection.on('disconnect', (connection) => {
                // connection.destroy();
                this.computeCallStatistics(moment());
                this.activeConnection = null;
                this.updateStatus(null);
            })

            // If phoneNumber is part of the connection, this is a call from a
            // support agent to a customer's phone
            if ("phoneNumber" in connection.message) {
                this.setState( { isCallInProgress: true });
            }


        }.bind(this));

        this.device.on('disconnect', function(connection) {
            this.setState( { isCallInProgress: false });
            this.updateStatus(null);

        }.bind(this));

        this.device.on('error', (error) => {
            if (error && error.code == 31201) {
                this.updateStatus(null, "Microphone issue2. Check your device.")
                return;
            }
            if (error) {
                this.updateStatus(null, "Unknown Device Error. Try again later.")
            }
            console.error("Device Error: " + JSON.stringify(error, null, 3));
        });

    }

    computeCallStatistics(connectionEndDate) {

        if (!this.state.connectionStartDate) {
            console.log("No connection happened - start date null");
            return;
        }
        console.log("startDate: " + this.state.connectionStartDate);
        console.log("endDate: " + connectionEndDate);

        //TODO: (!) change this. It's a hack (read call duration hack description above)
        let callDurationInSec = connectionEndDate.diff(this.state.connectionStartDate, 'seconds');
        console.log("Call duration in seconds: " + callDurationInSec);
        if (callDurationInSec < 8) {
            this.setState({ connectionStartDate: null });
            console.log("Probably connection has not happened");
            return;
        }

        let callDurationInMin = Math.ceil(callDurationInSec/60);
        let totalCallCost = callDurationInMin * this.state.callCost;

        console.log("totalCallCost: $" + totalCallCost);
        Auth.userProfile.credit -= totalCallCost;

        this.setState({ connectionStartDate: null });
    }

    render() {

        const userCanCall =(this.state.callCost ? (this.state.callCost <= Auth.userProfile.credit) : true);

        return (
            <Modal size={isMobile ? 'tiny' : 'mini'}
                   open={this.state.open}
                   closeOnEscape={false}
                   closeOnDimmerClick={false}
                   onClose={this.close}
                   trigger={
                       <Button size='large' className='btn-call' onClick={this.open}>
                           <Icon name='microphone'/>
                           Make a Call
                       </Button>
                   }>

                <Modal.Content className='make-call-modal-content'>

                    <Icon name='close'
                          onClick={this.close}
                          color='light grey' style={{position: 'absolute', top: '16px', right: '16px', opacity: '0.45', cursor: 'pointer'}} />

                    <div className='make-call-your-number-header'>
                        Your Number
                    </div>

                    <div className='make-call-your-number'>
                        {this.props.phone.number}
                    </div>

                    <div className='make-call-dest-number-header'>
                        Number You Want To Call
                    </div>

                    <div style={{fontSize: '31px', fontWeight: '500' }}>
                        <Input name='phoneNumber'
                               type="tel"
                               onChange={this.onValueChange} value={this.state.phoneNumber}
                               placeholder='E.g +1453234123'
                               style={{ width: '100%', border: '0px !important' }}>
                            <input className={`${ !this.state.phoneNumber || this.state.isNumberValid ? 'call-phone-input' : 'call-phone-input-invalid'}`}
                                   maxlength="30" style={{paddingBottom: '8px !important', paddingTop: '12px !important', paddingRight: '0px !important' }}/>
                        </Input>
                    </div>


                    <div>
                        {!this.state.computeCallCostError && !this.state.isCallCostComputed && (
                            <Button size='tiny' className='btn-cost'
                                    disabled={!this.state.isNumberValid}
                                    onClick={this.onComputeCostClick}
                                    style={{width: '100%', margin: '24px 0px 0px 0px'}}>

                                {!this.state.computeCallCostInProgress && (
                                    <div>
                                        <Icon name='dollar sign'/>
                                        <span>Show Cost Of The Call</span>
                                    </div>
                                )}

                                {this.state.computeCallCostInProgress && (
                                    <Loader active className='workaround' size='tiny' inline='centered'/>
                                )}

                            </Button>
                        )}

                        {!this.state.computeCallCostError && this.state.isCallCostComputed && (
                            <div style={{fontSize: '16px', fontWeight: '300', margin: '24px 0px 0px 0px'}}>
                                Cost : <span style={{marginLeft: '4px', fontWeight: '700', color: 'rgb(79, 187, 188)'}}> {this.state.callCost} credit / min</span>
                            </div>
                        )}

                        {this.state.computeCallCostError && (
                            <div style={{fontSize: '16px', fontWeight: '300', color: 'red', margin: '24px 0px 0px 0px'}}>
                                {this.state.computeCallCostError}
                            </div>
                        )}

                    </div>

                    <div>

                        {userCanCall && !this.state.isCallInProgress && (
                            <Button size='big' className='btn-call2'
                                    disabled={!this.state.isNumberValid}
                                    onClick={this.onCallClick}
                                    style={{width: '100%', margin: '24px 0px'}}>
                                <Icon name='microphone'/>
                                Call
                            </Button>
                        )}

                        { userCanCall && this.state.isCallInProgress && (
                            <Button size='big' className='btn-hangup' onClick={this.onHangUpClick}
                                    style={{width: '100%', margin: '24px 0px'}}>
                                Hang Up
                            </Button>
                        )}

                        { !userCanCall && (
                            <div className='make-call-msg-cannot-call'>
                                You don't have enough credits
                            </div>
                        )}
                    </div>

                    {this.state.status && (
                        <div style={{width: '100%', textAlign: 'center', marginBottom: '8px'}}>
                            <span style={{fontWeight: '300'}}>Status:</span> {this.state.status}
                        </div>
                    )}

                    {this.state.error && (
                        <div style={{width: '100%', textAlign: 'center', marginBottom: '8px'}}>
                            <span style={{fontWeight: '300', color: 'red'}}>{this.state.error}</span>
                        </div>
                    )}

                    <div style={{backgroundColor: '#f5fbfb', borderRadius: '2px', margin: '0 -52px -32px -52px', padding: '16px', fontWeight: '300', fontSize: '16px', color: "#111111", textAlign: 'center'}}>
                        Maximum call duration is <strong>3min</strong>.<br/>
                        <span style={{fontSize: '14px'}}>It's beta version.</span>
                    </div>

                </Modal.Content>
            </Modal>
        );
    }

}
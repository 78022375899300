import React from "react";
import 'semantic-ui-css/semantic.css';
import './IdentityDashboard.css';
import {Grid} from "semantic-ui-react";
import {IdentityList} from "./IdentityList";
import {Mixpanel} from "../utils/Mixpanel";
import CompromiseManager from "../utils/CompromiseManager";
import {NewIdentityView} from "./NewIdentityView";
import {IdentitiesStats} from "./IdentitiesStats";
import {SafetyStatus} from "./SafetyStatus";
import Vault from "../Vault";
import Auth from "../Auth";

export class IdentityDashboard extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            identitiesArr: this.mapToArray(Vault.identities),
            // identitiesArr: this.mapToArray(
            //     { 'slashdot.com' : [
            //             {
            //                 email: 'costdddddddddddm@itaintme.xyz',
            //                 password: 'haslo',
            //                 note: 'jakas notatka',
            //                 id: 'jakies-id'
            //             },
            //
            //         ],
            //         'facebook.com' : [
            //             {
            //                 email: 'costddddddddddddddddddddddddam@itaintme.xyz',
            //                 password: 'haslo',
            //                 note: 'jakas notatka',
            //                 id: 'jakies-id'
            //             },
            //
            //         ],
            //         'wp.pl' : [
            //             {
            //                 email: 'costamsssssssssssssssssss@itaintme.xyz',
            //                 password: 'haslo',
            //                 id: 'jakies-id',
            //                 isCompromised: true
            //             },
            //
            //         ],
            //         'allegro.pl' : [
            //             {
            //                 email: 'costadddddddddddddddddddm@itaintme.xyz',
            //                 password: 'haslo',
            //                 note: 'jakas notatka',
            //                 id: 'jakies-id'
            //             },
            //
            //         ],
            //         'airbnb.com' : [
            //             {
            //                 email: 'costddddddddddddddddddddddm@itaintme.xyz',
            //                 password: 'haslo',
            //                 note: 'jakas notatka',
            //                 id: 'jakies-id',
            //                 isCompromised: true
            //             },
            //
            //         ],
            //         'amazon.com' : [
            //             {
            //                 email: 'co3stasdfsdfsfsdfsdsdfsdfm@itaintme.xyz',
            //                 password: 'haslo',
            //                 note: 'jakas notatka',
            //                 id: 'jakies-id'
            //             },
            //
            //         ],
            //         'popkilasdfasdfasfd.asdfasdfasdf.asdfasdfssadfjlaksfjla.dfasdfasfd.asdfasdfasdf.asdfasdfssadfjlaksfjla.dsaklfjlskfjlkasjflkasjdflkasjlfkjaslkfjalskdfjlaksjflkasjflaksjflkasjlfkjsdfasdfasfd.asdfasdfasdf.asdfasdfssadfjlaksfjla.dsaklfjlskfjlkasjflkasjdflkasjlfkjaslkfjalskdfjlaksjflkasjflaksjflkasjlfkjsdfasdfasfd.asdfasdfasdf.asdfasdfssadfjlaksfjla.dsaklfjlskfjlkasjflkasjdflkasjlfkjaslkfjalskdfjlaksjflkasjflaksjflkasjlfkjsdfasdfasfd.asdfasdfasdf.asdfasdfssadfjlaksfjla.dsaklfjlskfjlkasjflkasjdflkasjlfkjaslkfjalskdfjlaksjflkasjflaksjflkasjlfkjsdsaklfjlskfjlkasjflkasjdflkasjlfkjaslkfjalskdfjlaksjflkasjflaksjflkasjlfkjsdlfkjasdlfkjaslkjflkasdjfadfsda.ler.pl' : [
            //             {
            //                 email: 'costam@itaintme.xyz',
            //                 password: 'haslo',
            //                 note: 'jakas notatka',
            //                 id: 'jakies-id'
            //             },
            //
            //         ],
            //
            //     }),

            width: window.innerWidth
        };

        this.onIdentityCreated = this.onIdentityCreated.bind(this);

    }

    componentDidMount() {
        Mixpanel.track('Identity Dashboard Visit');
    }

    componentWillMount() {
        window.addEventListener('resize', this.handleWindowSizeChange);
    }

// make sure to remove the listener
// when the component is not mounted anymore
    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ width: window.innerWidth });
    };

    onIdentityCreated(id) {
        this.setState({
            identitiesArr: this.mapToArray(Vault.identities),
        });
    }


    mapToArray(identites) {
        let arr = [];
        for(let domain in identites) {
            identites[domain].forEach(id => {
                arr.push({
                    ...id,
                    domain,
                    //TODO: comment this for testing only
                    isCompromised: CompromiseManager.isCompromised(id.id)
                });
            })
        }
        return arr;
    }

    render() {
        const isMobile = this.state.width <= 480;

        return (

            <div className='dashboard-wrapper'>

                <div className='dashboard'>

                    <Grid stackable style={{maxWidth: '1760px' }}>

                        <Grid.Row columns={2} className='dashboard-header' style={{maxWidth: '1760px'}}>

                            <Grid.Column mobile={16} tablet={16} computer={4}>
                                <IdentitiesStats identitiesArr={this.state.identitiesArr} />
                            </Grid.Column>

                            <Grid.Column mobile={16} tablet={16} computer={12}>
                                <NewIdentityView onIdentityCreated={this.onIdentityCreated}
                                                 totalLimitReached={this.state.identitiesArr.length >= Auth.userProfile.totalIdentityLimit}
                                />
                            </Grid.Column>

                        </Grid.Row>

                        <Grid.Row className='dashboard-header'  >
                            <Grid.Column mobile={16} tablet={16} computer={16} style={{paddingLeft: '0px', paddingRight: '0px'}}>
                                <SafetyStatus/>
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row columns={1}>
                            <IdentityList isMobile={isMobile} identities={this.state.identitiesArr}/>
                        </Grid.Row>

                    </Grid>

                </div>

            </div>

        )
    };

}


import React from "react";
import 'semantic-ui-css/semantic.css';
import Backend from "../Backend";
import Vault from "../Vault";
import {Mixpanel} from "../utils/Mixpanel";
import './MessageReader.css'
import {MailboxView} from "./MailboxView";

export class MessageReader extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            id : props.match.params.id,
            inProgress: (props.match.params.id),
            mailLoaded: false, //(!props.match.params.id),
            width: window.innerWidth,

            // from : 'from@email.com my text of the printing and type',
            // to: 'to@email.com my text of the printing and type',
            // subject: 'temat rem Ipsum is simply dummy text of the printing and typesetting industry.',
            // bodyText:  'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.'
        };

        this.parseMail = this.parseMail.bind(this);
        this.decryptEmail = this.decryptEmail.bind(this);
        this.handleReadMessage = this.handleReadMessage.bind(this);
    }

    componentDidMount() {
        Mixpanel.track('Messages Visit');

        if (!this.state.id) {
            // console.log("No message provided");
            return;
        }

        this.getAndDecryptMessage(this.state.id);
    }

    getAndDecryptMessage(id) {

        this.setState({
            id,
            inProgress: true})

        Backend
            .getUserMail( { id }, (response) => {
                // console.log("GOT response from getMail : " + JSON.stringify(response));
                if (!response || !response.status) {
                    this.setState({
                        inProgress: false,
                        mailLoaded: false
                    });
                    Mixpanel.track('Read Message failed');
                    return;
                }

                return {
                    identityId : response.identityId,
                    encryptedBody : response.body
                    // .replace(/\r/g, '')
                };
            })
            .then(this.decryptEmail);
    }

    async decryptEmail(mail) {
        // console.log("Start decrypting mail... " + JSON.stringify(mail));
        // console.log("MAIL.body: " + mail.encryptedBody);

        let id = Vault.getIdentityById(mail.identityId);
        if (!id) {
            Mixpanel.track('Mail for non-existent identity | SUSPICIOUS');
            // console.log("NO such identity");
            return;
        }

        let plainMail = await Vault.pgpDecryptMail(mail.encryptedBody, id.keys.public, id.keys.private, id.keys.keyPassword); //Vault.TEMP_KEY_PASSWD_TEST);
        // this.parseMail(id, plainMail);
        // .then(plainMail => this.parseMail(id, plainMail));

        // console.log("Start parsing mail...");
        let parsed = JSON.parse(plainMail);
        // console.log("Email parsed: " + JSON.stringify(parsed));

        this.setState({
            from : parsed.from.value[0].name + " (" + parsed.from.value[0].address + ")",
            to : id.note + " (" + parsed.to.value[0].address + ")",
            subject: parsed.subject,
            bodyText : parsed.text,
            bodyHtml : parsed.html,
            mailLoaded: true,
            inProgress: false
        });

    }


    parseMail(id, mail) {
        // console.log("Start parsing mail...");
        let parsed = JSON.parse(mail);
        // console.log("Email parsed: " + JSON.stringify(parsed));

        this.setState({
            from : parsed.from.value[0].name + " (" + parsed.from.value[0].address + ")",
            to : id.note + " (" + parsed.to.value[0].address + ")",
            subject: parsed.subject,
            bodyText : parsed.text,
            bodyHtml : parsed.html,
            mailLoaded: true,
            inProgress: false
        });
    }

    onValueChange = (e, { name, value }) => {
        this.setState({ [name]: value });
    }

    handleReadMessage() {
        if (this.state.inputId) {
            Mixpanel.track('Read Message clicked');
            this.getAndDecryptMessage(this.state.inputId.trim());
        } else {
            Mixpanel.track('Read Message clicked | Empty ID');
        }
    }

    render() {

        return (
            <div>
                <div className='mailbox-wrapper'>
                    <MailboxView/>
                </div>
            </div>
        )
    };

}
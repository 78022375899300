import axios from 'axios';
import config from './config'

const BACKEND_URL = config.API_URL;

export default class Backend {

    static async registerNewIdentity(params) {
        // const data = {
        //     type: "registerNewIdentity",
        //     params: params
        // };
        //
        // return Backend.callAWSAPI("identities", data, action);

        return axios.post(BACKEND_URL + '/identity/create', params)
            .then(res => { return Promise.resolve(res.data); })
            .catch(err => {
                console.log("err "+err);
                throw err;
            });
    }

    static signUp(params, action) {

        return axios.post(BACKEND_URL + '/auth/signup', params)
            .then(res => action(res.data))
            .catch(err => {
                console.log("err "+err);
                throw err;
            });
    }

    static login(params, action) {

        return axios.post(BACKEND_URL + '/auth/login', params)
            .then(res => action(res.data))
            .catch(err => {
                console.log("err " + err);
                throw err;
            });
    }

    static getVault(params) {
        return axios.post(BACKEND_URL + '/vault/get', params)
            .then(res =>  { return Promise.resolve(res.data); })
            .catch(err => {
                console.log("err "+err);
                throw err;
            });
    }

    static saveVault(params) {
        return axios.post(BACKEND_URL + '/vault/save', params)
            .then(res =>  { return Promise.resolve(res); })
            .catch(err => {
                console.log("err "+err);
                throw err;
            });
    }

    static updateVault(params) {
        return axios.post(BACKEND_URL + '/vault/update', params)
            .then(res =>  { return Promise.resolve(res); })
            .catch(err => {
                console.log("err "+err);
                throw err;
            });
    }

    static getCompromisedIds(params, action) {

        return axios.post(BACKEND_URL + '/identity/compromised/find', params)
            .then(res => action(res.data))
            .catch(err => {
                console.log("err "+err);
                throw err;
            });
    }

    static getUserMail(params, action) {

        return axios.post(BACKEND_URL + '/mail/get', params)
            .then(res => action(res.data))
            .catch(err => {
                console.log("err " + err);
                throw err;
            });
    }

    static getAllMailSummary(params) {

        return axios.post(BACKEND_URL + '/mail/summary/get', params)
            .catch(err => {
                console.log("err " + err);
                throw err;
            });

    }

    static getAllSmsSummary(params) {
        return axios.post(BACKEND_URL + '/sms/summary/get', params)
            .catch(err => {
                console.log("err " + err);
                throw err;
            });

    }

    static buyPhoneNumber(params) {
        return axios.post(BACKEND_URL + '/phone/buy', params)
            .then(res =>  { return Promise.resolve(this.processResponse(res)); })
            .catch(err => {
                console.log("err " + err);
                throw err;
            });
    }

    static getPhoneNumberDetails(params) {
        return axios.post(BACKEND_URL + '/phone/details/get', params)
            .then(res =>  { return Promise.resolve(res); })
            .catch(err => {
                console.log("err " + err);
                throw err;
            });
    }

    static getCallCost(params) {
        return axios.post(BACKEND_URL + '/phone/call/outbound/cost', params)
            .then(res =>  { return Promise.resolve(this.processResponse(res)); })
            .catch(err => {
                console.log("err " + err);
                throw err;
            });
    }

    static getTokenForPhoneCall(params) {
        return axios.post(BACKEND_URL + '/phone/call/token/generate', params)
            .then(res => { return Promise.resolve(this.processResponse(res)); })
            .catch(err => {
                console.log("err " + err);
                throw err;
            });
    }

    static getUriForRecognition(params) {
        return axios.post(BACKEND_URL + '/recognition/create', params)
            .then(res => { return Promise.resolve(this.processResponse(res)); })
            .catch(err => {
                console.log("err " + err);
                throw err;
            });
    }

    static verifyRecognition(params) {
        return axios.post(BACKEND_URL + '/recognition/verify', params)
            .then(res => { return Promise.resolve(this.processResponse(res)); })
            .catch(err => {
                console.log("err " + err);
                throw err;
            });
    }

    static isVerified(params) {
        return axios.post(BACKEND_URL + '/recognition/isVerified', params)
            .then(res => { return Promise.resolve(this.processResponse(res)); })
            .catch(err => {
                console.log("err " + err);
                throw err;
            });
    }

    static getIncomingCallToken(params) {
        return axios.post(BACKEND_URL + '/phone/call/incoming/token', params)
            .then(res =>  { return Promise.resolve(this.processResponse(res)); })
            .catch(err => {
                console.log("err " + err);
                throw err;
            });
    }

    static processResponse(response) {
        if (!response || !response.status || response.status !== 200 || !response.data) {
            throw "Response Error";
        }
        return response.data;
    }



}
import React from "react";
import 'semantic-ui-css/semantic.css';
import {Button, Grid, Icon} from "semantic-ui-react";
import './PhoneDashboard.css'
import Auth from "../Auth";
import {PhonesStats} from "./PhonesStats";
import {Mixpanel} from "../utils/Mixpanel";
import {BuyPhoneNumberView} from "./BuyPhoneNumberView";
import {isMobileOnly} from "react-device-detect";
import {PhoneList} from "./PhoneList";


export class PhoneDashboard extends React.Component {

    constructor(props) {
        super(props);
        this.phoneList = React.createRef();

        this.state = {
            id: props.match.params.id,
            inProgress: (props.match.params.id),

            totalCredit: Auth.userProfile.credit,
            showBuyPhoneNumber: !isMobileOnly,
            showBuyPhoneNumberMobileMainButton: isMobileOnly


            // phones: PhoneNumberManager.getAll(),

            // [
            //     {
            //         country: 'pl',
            //         number: '+48 698 888 321'
            //     },
            //     {
            //         country: 'us',
            //         number: '+1 321 22 83 42'
            //     }
            // ]

        };

        this.onBuyPhoneSuccess = this.onBuyPhoneSuccess.bind(this);
        this.showBuyPhoneNumberMobile = this.showBuyPhoneNumberMobile.bind(this);
    }

    componentDidMount() {
        Mixpanel.track('Phone phone-dashboard Visit');
        // this.setState({ phones : PhoneNumberManager.getAll()});
    }

    handleWindowSizeChange = () => {
        this.setState({width: window.innerWidth});
    };


    onValueChange = (e, {name, value}) => {
        this.setState({[name]: value});
    }

    onBuyPhoneSuccess(newPhone, cost) {
        // console.log("Adding new phone: " + JSON.stringify(newPhone, null, 3));
        this.setState(prevState => ({
            totalCredit: prevState.totalCredit - cost,
        }));

        this.phoneList.current.addSucceededPhoneId(newPhone.id)
    }

    showBuyPhoneNumberMobile(show) {
        this.setState({
            showBuyPhoneNumber: show,
            showBuyPhoneNumberMobileMainButton: !show
        });
    }

    render() {

        return (

            <div className='phone-dashboard-wrapper'>

                <div className='phone-dashboard'>

                    <Grid stackable style={{maxWidth: '1760px'}}>

                        <Grid.Row style={{maxWidth: '1760px'}}>

                            <Grid.Column mobile={16} tablet={16} computer={4}>
                                <PhonesStats credit={this.state.totalCredit}/>
                            </Grid.Column>

                            {this.state.showBuyPhoneNumberMobileMainButton && (
                                <Grid.Row columns={1} only='mobile tablet'>
                                    <Button onClick={() => this.showBuyPhoneNumberMobile(true)}
                                            className='buy-number-mobile-main-btn'>
                                        <Icon name='shopping cart' style={{marginRight: '8px'}}/>
                                        Get New Number
                                    </Button>

                                </Grid.Row>
                            )}


                            {this.state.showBuyPhoneNumber && (

                                <Grid.Column mobile={16} tablet={16} computer={12} className='new-number-content' style={{paddingBottom: '48px !important' }}>

                                    <BuyPhoneNumberView onBuyPhoneSuccess={this.onBuyPhoneSuccess}
                                                        showBuyPhoneNumberMobile={this.showBuyPhoneNumberMobile}

                                    />

                                </Grid.Column>

                            )}

                        </Grid.Row>


                    </Grid>

                    <PhoneList ref={this.phoneList}/>

                </div>
            </div>

        )
    };

}

import React from "react";
import _ from "lodash";
import {Grid} from "semantic-ui-react";
import './IdentitiesStats.css';

export class IdentitiesStats extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
        }
    }

    render() {

        const noCompromisedId = this.props.identitiesArr ? _.filter(this.props.identitiesArr, (id) => {return id.isCompromised}).length : 0;

        return (
                <Grid className='stats-wrapper' style={{padding: '0', textAlign: 'center', display: 'flex' }}>

                    <Grid.Row columns={2} style={{paddingTop: '0px !important', marginTop: '4px'}}>

                        <Grid.Column mobile={8} tablet={8} computer={8} widescreen={6} style={{ paddingLeft: '0px !important'}}>
                            <span className='statistic-label'>Identities</span>
                            <div className='statistic-value'>{this.props.identitiesArr ? this.props.identitiesArr.length : 0}</div>
                        </Grid.Column>

                        <Grid.Column mobile={8} tablet={8} computer={8} widescreen={6} style={{ paddingLeft: '0px !important'}}>
                            <span className='statistic-label'>Compromised </span>
                            <div className='statistic-value' style={{color: noCompromisedId == 0 ? '#21ba45' : '#f76262'}}>
                                {noCompromisedId}
                            </div>
                        </Grid.Column>

                    </Grid.Row>
                </Grid>

        )
    }
}
// import mixpanel from 'mixpanel-browser';


// WebApp ALPHA
// mixpanel.init('54ac37652d41e93e56fd0f3ce58ea866', {api_host: "https://api.mixpanel.com"});

// WebApp DEV
// mixpanel.init('588e7a1b8a23e0b564d1b03096363ae1---', {api_host: "https://api.mixpanel.com"});

// LandingPage token - for Subscribe only
// mixpanel.init('07cf782b6b792d3587eece8c95e659e9----', {api_host: "https://api.mixpanel.com"});


let env_check = true; //process.env.NODE_ENV === 'production';

let actions = {
    identify: (id) => {
        // if (env_check) mixpanel.identify(id);
    },
    alias: (id) => {
        // if (env_check) mixpanel.alias(id);
    },
    track: (name, props) => {
        // if (env_check) mixpanel.track(name, props);
    },
    people: {
        // set: (props) => {
        //     if (env_check) mixpanel.people.set(props);
        // },
    },
};

export let Mixpanel = actions;
import React from "react";
import {Button, Grid, Image, Popup} from "semantic-ui-react";
import './PhonesStats.css';

export class PhonesStats extends React.Component {

    constructor(props) {
        super(props);

        this.state = {}
    }

    handlePopupOpen(isPopupOpen) {
        return () => {
            console.log("handlePopupOpen !!!!")
            this.setState({ [isPopupOpen]: true })

            this.timeout = setTimeout(() => {
                this.setState({ [isPopupOpen]: false })
            }, 1500)
        };
    }

    handlePopupClose(isPopupOpen) {
        return() => {
            this.setState({[isPopupOpen]: false})
            clearTimeout(this.timeout)
        }
    }

    render() {

        const credit = this.props.credit ? this.props.credit : 0;

        return (
            <Grid className='phone-statistic'>
                <Grid.Row columns={1} only='computer'>
                    <Grid.Column tablet={16} computer={16} style={{paddingTop: '24px'}}>
                        <span className='phone-statistic-label'>Credits</span>
                        <div className='phone-statistic-value'>{Number.parseFloat(credit).toFixed(2)}</div>

                        <Popup
                            trigger={
                                <Button className='phone-statistic-buy-credit-button'>
                                    <Image src='/images/coins.svg'/>
                                    Add Credits
                                </Button>
                            }
                            content={`This option will be available soon`}
                            on='click'
                            open={this.state.isPopupOpen}
                            onClose={this.handlePopupClose('isPopupOpen')}
                            onOpen={this.handlePopupOpen('isPopupOpen')}
                            position='bottom center'
                        />

                    </Grid.Column>
                </Grid.Row>

                <Grid.Row columns={2} only='mobile tablet'>
                    <Grid.Column width={6} className='phone-statistic-header'>
                        <span className='phone-statistic-label'>Credits</span>
                        <div className='phone-statistic-value'>{Number.parseFloat(credit).toFixed(2)}</div>
                    </Grid.Column>
                    <Grid.Column width={10} style={{margin: 'auto'}}>
                        <div className='phone-statistic-buy-button-wrapper'>

                            <Popup
                                trigger={
                                    <Button className='phone-statistic-buy-credit-button'>
                                        <Image src='/images/coins.svg'/>
                                        Add Credits
                                    </Button>
                                }
                                content={`This option will be available soon`}
                                on='click'
                                open={this.state.isPopupTableOpen}
                                onClose={this.handlePopupClose('isPopupTableOpen')}
                                onOpen={this.handlePopupOpen('isPopupTableOpen')}
                                position='bottom center'
                            />


                        </div>
                    </Grid.Column>
                </Grid.Row>

            </Grid>
        )
    }
}
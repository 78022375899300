import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter, Route} from 'react-router-dom'
import GlobalContextProvider from "./components/context/GlobalContextProvider";
import * as Sentry from '@sentry/browser';
import config from './config'


if (config.env !== 'prod') {
    Sentry.init({
        dsn: "https://d90861f56ed74d29b6fabccc2c214567@sentry.io/5169776",
        environment: config.env
    });
}

ReactDOM.render(
    <GlobalContextProvider>
        <BrowserRouter>
            <Route path="/" component={ App }/>
        </BrowserRouter>
    </GlobalContextProvider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import Backend from "../Backend";
import Vault from "../Vault"
import Auth from "../Auth";
import moment from "moment";
import _ from "lodash";
import LRU from "lru-cache";
import GlobalContext from "../components/context/GlobalContext";
import {asyncForEach} from "./PromiseUtils";

export default class Mailbox {

    static contextType = GlobalContext;
    static lastUpdateDate;
    static allMailSummaryById = {};

    static MIN_TIMEOUT = 2000;
    static MAX_TIMEOUT = 6000;


    static cache = new LRU({
        max: 30,
        length: function (n, key) { return n * 2 + key.length },
        maxAge: 1000 * 60 * 60
    });

    static async loadAll(cb, forced) {

        const date = moment.utc();

        if (!forced &&
            (this.lastUpdateDate && (!date && !this.lastUpdateDate.isBefore(moment(date).subtract(5, 'minutes'))))) {
            return cb(true, 1, 1, this.allMailSummaryById);
        }

        const params = {
            lastUpdateDate: this.lastUpdateDate ? this.lastUpdateDate.format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS) : undefined
        };

        const mailSummaryRequests = this.getAllMailSummaryRequests(params);
        let requestSucceeded = true;
        let newEmailAvailable = false;
        const mailSummaryRequestSize = mailSummaryRequests.length;

        if (mailSummaryRequests.length < 1) {
            return cb(true, 1, 1, this.allMailSummaryById);
        }

        await asyncForEach(
            mailSummaryRequests,
            async (req, index) => {
                await req.then(async (response) => {
                    if (!response || !response.data || !response.data.status) {
                        requestSucceeded = false;
                        return cb(requestSucceeded, index + 1, mailSummaryRequestSize, this.allMailSummaryById);
                    }

                    let summariesById = await this.decryptSummaryList(response.data.data);
                    await this.merge(summariesById, date);
                    newEmailAvailable = newEmailAvailable || this.checkForNewEmails(summariesById);

                    return cb(requestSucceeded, index + 1, mailSummaryRequestSize, this.allMailSummaryById, newEmailAvailable);
                })
                    .catch(err => {
                        console.log("Error when loading mails lists : " + err);
                        requestSucceeded = false;
                        return cb(requestSucceeded, index + 1, mailSummaryRequestSize, this.allMailSummaryById);
                    });
            });
        if (requestSucceeded) {
            this.lastUpdateDate = date;
        }
    }

    static getAllMailSummaryRequests(params) {
        return Vault.getAllIdentityIds()
            .sort( () => Math.random() - 0.5)
            .map(async (id, index) => {
                await new Promise(resolve => setTimeout(resolve, index > 0 ? index * this.getRandomNumber() : 0));
                return Auth.isLoggedIn ? Backend.getAllMailSummary({...params, id: id}) : Promise.reject("User logged out");
            });
    }

    static getRandomNumber() {
        return Math.floor(Math.random() * (this.MAX_TIMEOUT - this.MIN_TIMEOUT)) + this.MIN_TIMEOUT;
    }

    static async decryptSummaryList(allSummaryLists) {

        let summariesById = _.groupBy(allSummaryLists, s => s.identityId);

        for (let id in summariesById) {

            let encryptedSummaries = summariesById[id];
            const identity = Vault.getIdentityById(id);
            let plainSummaries = [];

            for (let idx = 0; idx < encryptedSummaries.length; idx++) {
                let s = encryptedSummaries[idx];

                await Vault.pgpDecrypt(s.encryptedSummary, identity.keys.public, identity.keys.private, identity.keys.keyPassword)
                    .then(jsonSummary => {

                        let summary = JSON.parse(jsonSummary);
                        summary.mailId = s.id;
                        summary.identityId = id;
                        summary.isCompromised = s.isCompromised;
                        summary.alreadyRead = s.alreadyRead;

                        plainSummaries.push(summary);

                        // if (Object.keys(this.allMailSummaryById).length == 0 && plainSummaries.length == 3) {
                        //     plainSummaries.shift();
                        // }

                    })
                    .catch(err => {
                        console.error("Problem with decoding email summary ", err);
                    });
            }

            summariesById[id] = plainSummaries;
        }

        return summariesById;
    }

    static async merge(newMailSummaries, updateDate) {

        if ((!this.lastUpdateDate || (this.lastUpdateDate && this.lastUpdateDate.isBefore(updateDate))) && newMailSummaries) {

            for(let identity in newMailSummaries) {
                if (!this.allMailSummaryById[identity]) {
                    this.allMailSummaryById[identity] = [];
                }
                const mergedMessages = _.concat(newMailSummaries[identity], this.allMailSummaryById[identity]);
                const recentMessages = _.orderBy(mergedMessages, ["date"], ["desc"]);
                this.allMailSummaryById[identity] = _.uniqBy(recentMessages, 'mailId').slice(0, 10);
            }
        }

    }

    static checkForNewEmails(summariesById) {

        let newEmailAvailable = false;

        for(let identity in summariesById) {

            if (summariesById[identity] == null) {
                return;
            }

            summariesById[identity].forEach(newSummary => {
                newEmailAvailable = newEmailAvailable | moment(newSummary.date).utc().isAfter(Auth.loginDate.utc());
            });
        }

        return newEmailAvailable;
    }

    static async refresh(cb) {
        // return this.loadAll(moment('2019-05-28T06:07:15.000Z'), true);
        this.loadAll(cb, true);
    }

    static async getMail(id, identityId, mailNotReadCallback) {

        if (this.cache.has(id)) {
            return Promise.resolve(this.cache.get(id));
        }

        const isMessageAlreadyRead = this.isMessageAlreadyRead(id);
        if (!isMessageAlreadyRead) {
            this.setMessageAlreadyRead(id);
            mailNotReadCallback(this.allMailSummaryById);
        }

        return Backend
            .getUserMail( { id }, (response) => {

                if (!response || !response.status) {
                    throw "Invalid Response";
                }

                return {
                    identityId,
                    encryptedBody : response.data
                };

            })
            .then(this.decryptEmail)
            .then(mail => {
                this.cache.set(id, mail);
                return Promise.resolve(mail);
            })
            .catch(err => {
                console.error("Mail read/decrypt error", err);
                return Promise.reject("Invalid response");
            });

    }

    static isMessageAlreadyRead(id) {
        for (const mails of Object.values(this.allMailSummaryById)) {
            for (const mail of mails) {
                if (mail.mailId === id) {
                    return mail.alreadyRead;
                }
            }
        }

        return true;
    }

    static setMessageAlreadyRead(id) {
        for (const mails of Object.values(this.allMailSummaryById)) {
            for (const mail of mails) {
                if (mail.mailId === id) {
                    mail.alreadyRead = true;
                    return;
                }
            }
        }
    }

    static async decryptEmail(encryptedMailMetadata) {

        if (!encryptedMailMetadata) {
            throw "no mail";
        }

        let id = Vault.getIdentityById(encryptedMailMetadata.identityId);
        if (!id) {
            throw "No such identity";
        }

        return Vault
            .pgpDecryptMail(encryptedMailMetadata.encryptedBody, id.keys.public, id.keys.private, id.keys.keyPassword)
            .then(plainMail => {

                let parsed = JSON.parse(plainMail);

                return Promise.resolve({
                    text: parsed.text,
                    html: parsed.html,
                    date : parsed.date
                });
            })
            .catch(err => {
                return Promise.reject(err);
            })
    }

}

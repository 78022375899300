import React from "react";
import 'semantic-ui-css/semantic.css';
import {Button, Dimmer, Grid, Icon, Image, Input, Loader, Popup, Segment} from "semantic-ui-react";
import Auth from "../Auth";
import {browserHistory} from 'react-router';
import Vault from "../Vault";
import CompromiseManager from "../utils/CompromiseManager";
import {Mixpanel} from "../utils/Mixpanel";
import './Login.css'
import CryptoUtils from "../utils/CryptoUtils";
import {TwilioDevice} from "../utils/TwilioDevice";

const validator = require("email-validator");

export class Login extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            inProgress: false,
            loginFailed: false,
            noidPassword: null,
            noidUsername: null,
            width: window.innerWidth,
        };

        this.handleLoginClick = this.handleLoginClick.bind(this);
        this.goToDashboard = this.goToDashboard.bind(this);
        this.onSignUpClick = this.onSignUpClick.bind(this);
    }

    componentDidMount() {
        Mixpanel.track('Login Page Visit');
    }

    componentWillMount() {
        window.addEventListener('resize', this.handleWindowSizeChange);
    }

// make sure to remove the listener
// when the component is not mounted anymore
    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ width: window.innerWidth });
    };

    validateUsername(username) {
        if (!username || username.length < 6 || username.length > 48) {
            return false;
        }
        const re = /^[0-9a-zA-Z]+$/g;
        return re.test(username);
    }

    handleLoginClick(e) {
        e.preventDefault();

        Mixpanel.track('Login | Login clicked');

        // if (!validator.validate(this.state.noidUsername)) {
        if (!this.validateUsername(this.state.noidUsername)) {
            this.setState({
                invalidEmail: true,
                inProgress : false
            });

            Mixpanel.track('Login | Validation failed');
            return;
        }

        this.setState({
            invalidEmail : false,
            inProgress: true,
            loginFailed: false
        });

        Auth.login(this.state.noidUsername, this.state.noidPassword, (result) => {

            if (!result.status) {
                this.setState({
                    loginFailed: true,
                    inProgress: false
                });
                Mixpanel.track('Login failed');
                return;
            }

            Mixpanel.track('Login success');

            Vault.initIdentities(result.vaultId, this.state.noidPassword, (identities) => {

                CompromiseManager.checkForCompromises()
                    .then(() => {
                        this.goToDashboard()
                    })
                    .catch(err =>{
                        console.error("Problem when checking compromised IDs. But go to dashboard anyway : " + err);
                        this.goToDashboard()
                    });

                TwilioDevice.startTwilioDevices();
            });

        });

    }

    goToDashboard() {

        let goto = '/main';

        if (this.props.location.state && this.props.location.state.from && this.props.location.state.from.pathname !== '/') {
            goto = this.props.location.state.from.pathname + this.props.location.state.from.search;
        }

        this.props.history.push(goto);

        // this.props.history.push('/main');
        // this.props.history.push('/message/34cf80d5-e881-49f4-bf2f-11f27a2ba0dc');
    }

    onValueChange = (e, { name, value }) => {
        this.setState({ [name]: value });
    }

    onSignUpClick() {
        Mixpanel.track('Login | Sign Up clicked');
        this.props.history.push('/register');
    }


    render() {
        const isMobile = this.state.width <= 480;
        return (

            <Grid style={{width: '100vw', height: '100vh', marginTop: '0rem', marginBottom: '0rem'}} className='login-grid-wrapper' stackable>
                <Grid.Row columns={2} style={{width: '100%', height: '100%', paddingTop: '0px !important'}}>
                    <Grid.Column width={10} className='login-desc-wrapper' style={{padding: '0px !important'}}>

                        <Image size='tiny' className='login-logo' src='/images/logo-small.png'/>

                        <div className='login-head'>
                            {/*<p className='login-header'>*/}
                            {/*Stop struggling to control*/}
                            {/*{!isMobile && (<br/>)}*/}
                            {/*<span style={{fontWeight: '700'}}> who knows what about you.</span>*/}
                            {/*</p>*/}
                            <div className='login-subheader'>
                                Mask your Identity.
                            </div>
                            <div className='login-subheader2'>
                                Get peace of mind.
                            </div>
                        </div>
                    </Grid.Column>
                    <Grid.Column width={6} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>

                        {/*<Image size='tiny' src='/images/logo-small.png' style={{ position: 'absolute', top: '48px', right: '128px', margin: '0 auto' }}/>*/}

                        <Segment.Group style={{textAlign: 'center', maxWidth: '500px', boxShadow: 'none', border: '0'}}>

                            <Segment>

                                <Input
                                    error={this.state.invalidEmail}
                                    disabled={this.state.inProgress}
                                    style={{width: '90%', marginBottom: '16px', fontSize: '18px'}} icon='user' iconPosition='left'
                                    name='noidUsername' placeholder='Username' value={this.state.noidUsername}
                                    onChange={this.onValueChange}/>

                                <Input
                                    disabled={this.state.inProgress}
                                    style={{width: '90%', fontSize: '18px'}} type='password' icon='lock' iconPosition='left'
                                    name='noidPassword' value={this.state.noidPassword} placeholder='Password'
                                    onChange={this.onValueChange}/>

                                {this.state.loginFailed && (
                                    <div style={{color: 'red', marginTop: '16px'}}>Login failed. Please try again</div>
                                )}

                            </Segment>

                            <Segment>

                                {!this.state.inProgress && (
                                    <Button color='black' onClick={this.handleLoginClick}
                                            style={{color: 'white', width: '90%', margin: '0 auto', fontSize: '18px'}}>
                                        Login</Button>
                                )}

                                {this.state.inProgress && (
                                    <Segment style={{height: '38px', width: '90%', margin: '0 auto', fontSize: '18px'}}>
                                        <Dimmer active>
                                            <Loader/>
                                        </Dimmer>
                                    </Segment>
                                )}

                                <div
                                    onClick={this.onSignUpClick}
                                    disabled={this.state.inProgress}
                                    style={{color: 'grey', fontSize: "20px", margin: '16px 0 0 0', cursor: 'pointer'}}>
                                    <span>Create account</span>
                                </div>

                            </Segment>

                            <Popup trigger={
                                <div style={{color: '#a8a8a8', fontSize: '16px', marginTop: '48px'}}>
                                    <Icon name='question circle outline' color='grey'/> Why do I have to login so often?
                                </div>
                            } position='bottom center' content='no.ID does not keep your unencrypted data anywhere.
                                                                When you refresh/open the application again, you need to provide
                                                                your password to decode your data locally only for this particular session.'/>


                        </Segment.Group>

                    </Grid.Column>

                </Grid.Row>
                <Grid.Row>

                </Grid.Row>
            </Grid>

        )
    };

}


import Backend from "../Backend";
import Vault from "../Vault"
import Auth from "../Auth";

export default class CompromiseManager {

    static compromisedIds = null;

    static async checkForCompromises() {

        if (!Vault.identities) {
            return Promise.reject();
        }

        let ids = [];
        for(let domain in Vault.identities) {
            Vault.identities[domain].forEach(id => {
                if (id.id) {
                    ids.push(id.id);
                }
            });
        }

        if (ids.length == 0) {
            this.compromisedIds = new Set([]);
            return Promise.resolve(this.compromisedIds);
        }

        return Backend.getCompromisedIds({sessionToken : Auth.sessionToken , ids} , (response => {

            // if (!response.status || !response.data || !Array.isArray(response.data)) {
            if (!response.data || !Array.isArray(response.data)) {
                return Promise.resolve(null);
            }

            this.compromisedIds = new Set(response.data);

            return Promise.resolve(this.compromisedIds);
        }));

    }

    static isCompromised(id) {
        return (this.compromisedIds && this.compromisedIds.has(id));
    }

}

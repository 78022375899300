import Backend from "../Backend";
import Auth from "../Auth";
import Vault from "../Vault";
import _ from "lodash";
import {TwilioDevice} from "./TwilioDevice";

export default class PhoneNumberManager {

    static COUNTRIES = new Set(['us', 'is', 'gb']);

    // static phones;

    static async getNew(country) {

        if (!country || !this.COUNTRIES.has(country)) {
            return Promise.reject();
        }

        const keys = await Vault.generateRsaKeys();

        const params = {
            sessionToken : Auth.personalisedSessionToken,
            country: country.toUpperCase(),
            voice: true,
            pub_key: keys.public
        }

        return Backend.buyPhoneNumber(params)
            .then(response => {
                if (!response || !response.status || !response.phone) {
                    throw "Failed";
                }
                console.log("BuyPhoneNumber | " + JSON.stringify(response.phone, null, 3));

                const newPhone = {
                    ...response.phone,
                    keys
                };
                console.log("BuyPhoneNumber | new phone after adding keys: " + JSON.stringify(newPhone, null, 3));


                return Vault.addPhoneNumber(newPhone).then(status => {
                    console.log("addPHoneNumber to vault | status: " + status);
                    if (status) {
                        // if (!this.phones) {
                        //     this.phones = Vault.phones;
                        // }
                        // this.phones.push(response.phone);

                        TwilioDevice.startTwilioDevice(response.phone.clientId);
                        return response.phone;
                    }
                    throw "Problem saving number to Vault";
            })


            })
            .catch(err => {
                console.log("Problem buying Phone Number : " + err);
                throw "Problem buying Phone Number"
            });

    }

    static getAll() {
        // if (!this.phones) {
        //     this.phones = _.cloneDeep(Vault.phones);
        // }
        console.log("phoneNumberManager.getAll() called");
        // console.log("PhoneManager | loadedPhones: " + JSON.stringify(this.phones, null, 3));
        return Vault.phones; //this.phones;
    }

    static getAllPhonesIds() {
        return Vault.getAllPhonesIds();
    }

    static getAllClientIds() {
        return Object.values(Vault.phones)
            .map(phone => phone.clientId);
    }

}

import React from "react";
import {Button, Grid, Icon} from "semantic-ui-react";
import './RecognitionView.css';
import Auth from "../Auth";
import Vault from "../Vault";
import Backend from "../Backend";

export class RecognitionView extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            code: '',
            state: ''
        };

        this.getRecognitionUri = this.getRecognitionUri.bind(this);
    }

    getRecognitionUri() {

        const params = {
            sessionToken : Auth.sessionToken,
            ownerIdentityId: Vault.ownerIdentityId,
        };

        return Backend
            .getUriForRecognition(params)
            .then(response => {

                if (!response || !response.status) {
                    return Promise.reject("Invalid response");
                }

                setTimeout(function(){
                    window.location.assign(response.recognitionUri);
                    window.location.href = response.recognitionUri;
                    return false;
                },500);
            })
            .catch(err => {
                console.log("Error: " + err);
                return Promise.reject("Network problem");
            });
    }

    render() {
        return (
            <Grid stackable className='recognition-view' style={{backgroundColor: '#06778e', paddingLeft: '0px !important'}}>
                <Grid.Column width={2} className='recognition-icon-wrapper'>
                    <Icon className='recognition-icon' name='id card' size='huge'/>
                </Grid.Column>
                <Grid.Column width={9} className='recognition-text'>
                    Phone Number Regulations in this country require providing identity
                    documentation to carriers in exceptional cases. <br className="mobile-break"/><b style={{fontSize: '14px'}}>LEARN MORE</b>
                </Grid.Column>
                <Grid.Column width={5} className='recognition-button-wrapper'>
                    <Button className='recognition-button' onClick={this.getRecognitionUri}>
                        <Icon name='shield'/>
                        Verify Me
                    </Button>
                </Grid.Column>
            </Grid>
        );
    }
}


import Backend from "./Backend";
import jsrp from 'jsrp';
import moment from "moment";


export default class Auth {

    static isLoggedIn = false;
    static loginDate = null;
    static sessionToken;
    static personalisedSessionToken;
    static userProfile = {};

    static login(username, password, action) {

        Backend.login({email: username}, (response) => {

            if (!response || !response.status) {
                console.error("ERROR when logging");
                action({ status: false });
                return;
            }

            let jsrpClient = new jsrp.client();
            jsrpClient.init({username, password}, function () {

                jsrpClient.setSalt(response.salt);
                jsrpClient.setServerPublicKey(response.B);

                const pk = jsrpClient.getPublicKey();

                const params = {
                    sessionToken: response.sessionToken,
                    A: jsrpClient.getPublicKey(),
                    M1: jsrpClient.getProof()
                };

                Backend.login(params, (response2) => {

                    if (!response2 || !response2.status) {
                        console.error("ERROR when logging | step 2");
                        action(false);
                        return;
                    }

                    if (jsrpClient.checkServerProof(response2.M2)) {

                        Auth.sessionToken = response2.sessionToken;
                        Auth.personalisedSessionToken = response2.personalisedSessionToken;
                        Auth.isLoggedIn = true;
                        Auth.loginDate = moment();

                        Auth.userProfile = {
                            credit: response2.credit,
                            totalIdentityLimit: response2.totalIdentityLimit ? response2.totalIdentityLimit : 5,
                            username
                        };

                        action({ status: true, vaultId: response2.vaultId });
                    } else {
                        console.log("LOGIN failed");

                        Auth.sessionToken = null;
                        Auth.isLoggedIn = false;
                        Auth.userProfile = {};

                        action({ status: false });

                        return false;
                    }

                })
            });

        })
            .catch(err => {
                Auth.sessionToken = null;
                Auth.personalisedSessionToken = null;
                Auth.isLoggedIn = false;
                Auth.userProfile = {};
                action(false);
            });

    }

    static signUp(username, password, code, action) {
        let jsrpClient = new jsrp.client();

        jsrpClient.init({ username, password }, function () {
            jsrpClient.createVerifier(function(err, result) {
                // result will contain the necessary values the server needs to
                // authenticate this user in the future.

                const params = {
                    email : username,
                    salt : result.salt,
                    verifier : result.verifier,
                    code: code
                };

                Backend.signUp(params, (result) => {

                    //Response from SignUp API : {"status":true,"vaultId2":"d4fa74ac-f234-4f12-ad70-cf1e70411fc1","ownerIdentityId":"629afd498f23cfb2083f4233d7f7793c4fd60a08c803d010b46d2f21c33a94b8c16acb2e5ba873dd9ad90cecdb26575003461291878dab6c3704d5b191de663d"}

                    if (!result || !result.status) {
                        console.log("Account creation : FAILURE");
                        action({ status: false });
                        return;
                    }

                    action(result); //true, { vaultId: result.vaultId2, ownerIdentityId: result.ownerIdentityId });
                })
                .catch(err => {
                  console.log("Account creation : FAILURE");
                  action({ status: false });
                });
            });
        });

    }


}
